<div class="container">
    <div class="row mt-3">
        <div class="col-6">
            <div class="card">
                <form [formGroup]="form">
                    <mat-grid-tile-header class="p-4">
                        <h3>{{"SELECT_PROFILE" | translate}}</h3>
                    </mat-grid-tile-header>
                    <div class="card-min-hight">
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"LBL_PROFILE_EMAIL" | translate}}</mat-label>
                                <input matInput formControlName="email"
                                    placeholder="{{'PLACEHOLDER_EMAIL' | translate}}" disabled>
                                <mat-icon aria-hidden="false" class="icon-append" fontIcon="mail"></mat-icon>
                                <mat-error *ngIf="form.get('email').errors?.required && form.get('email').touched">
                                    {{"ERROR_VALIDATION" | translate}}
                                </mat-error>
                                <mat-error *ngIf="form.get('email').hasError('pattern') && form.get('email').touched">
                                    {{"ERROR_EMAIL" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>{{"LBL_PASSWORD" | translate}}</mat-label>
                                <input [type]="isShow ? 'password' : 'text'" matInput formControlName="password"
                                    class="border-none" placeholder="enter password">
                                <mat-icon aria-hidden="false" class="icon-append" (click)="isShow = !isShow">{{isShow ?
                                    'visibility_off' : 'visibility'}}></mat-icon>

                                <mat-error *ngIf="form.get('password').invalid && form.get('password').touched">
                                    {{"ERROR_VALIDATION" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                    </div>

                    <!-- <button class="btn btn-primary" (click)="updateProfile()">{{ "BTN_UPDATE" | translate
                        }}</button> -->
                </form>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <form [formGroup]="changePasswordForm">
                    <mat-grid-tile-header class="p-4">
                        <h3>{{ "LBL_CHANGEPASSWORD_RESET" | translate }}</h3>
                    </mat-grid-tile-header>
                    <div class="card-min-hight">
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ "LBL_OLDPASSWORD" | translate }}</mat-label>
                                <input matInput [type]="isShowPassword ? 'text' : 'password'"
                                    formControlName="oldPassword" class="border-0"
                                    placeholder="{{ 'LBL_OLDPASSWORD' | translate }}" (keypress)="preventSpace($event)"
                                    maxlength="30">
                                <mat-icon aria-hidden="false" class="icon-append"
                                    (click)="isShowPassword = !isShowPassword">{{isShowPassword ?
                                    'visibility_off' : 'visibility'}}></mat-icon>
                                <mat-error
                                    *ngIf="changePasswordForm.get('oldPassword').invalid && changePasswordForm.get('oldPassword').touched">
                                    {{"ENTER_OLD_PASSWORD" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ "LBL_NEWPASSWORD" | translate }}</mat-label>
                                <input matInput [type]="isShowNewPassword ? 'text' : 'password'"
                                    formControlName="newPassword" placeholder="{{ 'LBL_NEWPASSWORD' | translate }}"
                                    (keypress)="preventSpace($event)" (input)="passwordMatchValidator()" maxlength="30"
                                    autocomplete>
                                <mat-icon aria-hidden="false" class="icon-append"
                                    (click)="isShowNewPassword = !isShowNewPassword">{{isShowNewPassword ?
                                    'visibility_off' : 'visibility'}}></mat-icon>
                                <!-- <mat-icon aria-hidden="false" class="icon-append" fontIcon="lock"></mat-icon> -->
                                <mat-error
                                    *ngIf="changePasswordForm.get('newPassword').errors?.required && changePasswordForm.get('newPassword').touched">
                                    {{"ENTER_NEW_PASSWORD" | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ "LBL_CONFIRMPASSWORD" | translate }}</mat-label>
                                <input matInput [type]="isShowConfirmPassword ? 'text' : 'password'"
                                    formControlName="confirmPassword" (paste)="(false)"
                                    (keypress)="preventSpace($event)" (input)="passwordMatchValidator()"
                                    placeholder="{{ 'LBL_CONFIRMPASSWORD' | translate }}" maxlength="30">
                                <mat-icon aria-hidden="false" class="icon-append"
                                    (click)="isShowConfirmPassword = !isShowConfirmPassword">{{isShowConfirmPassword ?
                                    'visibility_off' : 'visibility'}}></mat-icon>
                                <!-- <mat-icon aria-hidden="false" class="icon-append" fontIcon="lock"></mat-icon> -->
                                <mat-error
                                    *ngIf="changePasswordForm.get('confirmPassword').errors?.required && changePasswordForm.get('confirmPassword').touched">
                                    {{"CONFIRM_NEW_PASSWORD" | translate}}
                                </mat-error>
                                <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.errors?.['mismatch']">
                                    {{"ERROR_PASSWORD" | translate}} </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr>

                    <button class="btn btn-primary" type="button" (click)="updatePassword()"
                        [disabled]="isChangePassword">{{ "BTN_UPDATE" | translate
                        }}</button>
                </form>
            </div>
        </div>
    </div>
</div>