import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  public loader = false;
  public getCurrentLang$: Observable<any>;
  public isShowLoader$ = new Subject<any>();
  public langSubject = new Subject<any>();
  private langValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor(private translate: TranslateService, private toasterService: ToastrService) {
    this.getCurrentLang$ = this.langSubject.asObservable();
  }

  // Get local storage token
  getLocalStorage(key: any) {
    let value = localStorage.getItem(key);
    if (value) {
      return value;
    }
    return '';
  }

  setCurrentLang(key: string, data: any) {
    const dataString = JSON.stringify(data);
    localStorage.setItem(key, dataString);
    this.langSubject.next(dataString);
  }

  updateLanguage(newValue: string): void {
    this.langValue.next(newValue);
  }

  getCurrentLang(): BehaviorSubject<string> {
    return this.langValue;
  }

  // Remove Local storage token
  removeLocalStorageToken(key: any) {
    localStorage.removeItem(key);
  }

  // Set local storage token
  setLocalStorageToken(key: any, value: any) {
    localStorage.setItem(key, value);
  }

  // Set local storage data by key
  setLocalStorage(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getLang(): Observable<any> {
    return this.langSubject.asObservable();
  }

  //Remove local storage data by key
  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  //Remove All local storage data by key
  removeAllLocalStorage() {
    localStorage.clear();
  }

  error(error) {
    this.toasterService.error(error);
  }

  success(response) {
    this.toasterService.clear();
    setTimeout(() => {
      this.toasterService.success(response);
    }, 400);
  }

  initializeTranslation() {
    let currentLanguage = JSON.parse(this.getLocalStorage('currentLang'));
    if (currentLanguage) {
      this.translate.setDefaultLang(currentLanguage);
      this.translate.use(currentLanguage);
    }
    // this.translate.addLangs(['en', 'fr']);  comment 28-10-2023
    // this.translate.setDefaultLang('en');
    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  getSwalErrorPopUp(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.translate.instant('LBL_OOPS'),
      text: this.translate.instant(message),
      confirmButtonText: this.translate.instant('BTN_OKAY'),
    })
    return
  }

  getSwalSuccessPopUp(message: string) {
    Swal.fire({
      icon: 'success',
      title: this.translate.instant('LBL_CONTROLLER_SUCCESS'),
      text: this.translate.instant(message),
      confirmButtonText: this.translate.instant('BTN_OKAY'),
    })
    return
  }

  confirmAction(title: string, text: string, confirmButtonText: string, cancelButtonText: string): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    }).then((result) => {
      return result.isConfirmed;
    });
  }

  // Remove Empaty keys
  public removeEmptyKeys(obj: any) {
    return Object.entries(obj).reduce((result: any, [key, value]) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && !value.length)
      )
        result[key] = value;
      return result;
    }, {});
  }

  // Export to Excel

  exportTableToExcel(tableId: string, name?: string) {
    let targetTableElm = document.getElementById(tableId);

    // Remove last two columns from the table
    let rows = targetTableElm.querySelectorAll('tr');
    rows.forEach(row => {
      let cells = row.querySelectorAll('td, th');
      for (let i = cells.length - 1; i >= cells.length - 2; i--) {
        if (cells[i]) {
          row.removeChild(cells[i]);
        }
      }
    });
    let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
      sheet: name
    });
    XLSX.writeFile(wb, `${name}.xlsx`);
  }

  // exportArrayToExcel(arr: any[], name?: string) {

  //   var wb = XLSX.utils.book_new();
  //   var ws = XLSX.utils.json_to_sheet(arr);
  //   XLSX.utils.book_append_sheet(wb, ws, name);
  //   XLSX.writeFile(wb, `${name}.xlsx`);
  // }

  public showLoader() {
    if (!this.loader) this.loader = true;
    this.isShowLoader$.next(true);
  }

  public hideLoader() {
    this.loader = false;
    this.isShowLoader$.next(false);
  }

  showSpinner(): Observable<any> {
    return this.isShowLoader$.asObservable();
  }

  formatMonthName(date: string | Date | undefined, locale: string): string {
    // Check if date is a string and can be parsed as a Date
    let parsedDate: Date;

    if (typeof date === 'string') {
      parsedDate = new Date(date);
    } else if (date instanceof Date) {
      parsedDate = date;
    } else {
      return ''; // or a default value indicating missing date
    }

    // Check if parsedDate is valid
    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid date:', date);
      return ''; // or a default value indicating error
    }

    const formatOptions: { [key: string]: string } = {
      en: 'MMM d, y',
      fr: 'd MMM y',
    };

    // Default to 'en' if locale is not supported
    const format = formatOptions[locale] || formatOptions['en'];
    
    return formatDate(parsedDate, format, locale);
  }
}

export class NoWhiteSpaceValidator {
  static noWhiteSpaceValidator(fc: FormControl) {
    const value = fc.value;
    if (value && value.toString().trim().length === 0) {
      return { noWhiteSpaceValidator: true };
    }
    return null;
  }
}
