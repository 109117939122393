import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// import { Breadcrumb } from 'ng-dynamic-breadcrumb/lib/breadcrumb.model';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';;
// import {BreadCrumb} from './breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  
  // breadcrumbs$: Observable<Breadcrumb[]>; 

  // constructor(private breadcrumbService: BreadcrumbService) {};

  ngOnInit(){
    // this.breadcrumbService.set('mentors', 'Mentor View'); 
    // this.breadcrumbService.set('mentor/:id', 'Uday Vunnam');
  }

 

 

}
