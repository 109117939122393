import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnConfig } from 'src/app/interface/coloumnConfig.interface';
import { CommonService } from '../../services/common.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-custome-table',
  templateUrl: './custome-table.component.html',
  styleUrls: ['./custome-table.component.scss'],
})
export class CustomeTableComponent implements AfterViewInit, OnChanges {
  @Input() columnConfigs: ColumnConfig[];
  @Input() currentPage: number;
  @Input() dataSource = new MatTableDataSource();
  @Input() totalPosts = 0;
  @Input() postsPerPage = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  @Input() filter = '';
  currentLanguage: string;
  @Input() dataSourceChange: boolean;
  @Input() customActions: any[] = [];
  @Input() targetDetailsTemplate: TemplateRef<any>;
  @Input() offerValidityTemplate: TemplateRef<any>;

  @Output() actionEvent = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() pdfView = new EventEmitter<any>();
  @Output() upload = new EventEmitter<any>();
  @Input() isPaginationShowed: boolean = true
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) sort1: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  columnKeys: string[] = [];
  @Output() sortCol = new EventEmitter();

  constructor(private common: CommonService, private apiService: ApiService) {
    this.currentLanguage = JSON.parse(this.common.getLocalStorage('currentLang'));
  }
  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item: any, property) => {
        const column = this.columnConfigs.find(col => col.key === property);
        return column?.sortingAccessor ? column.sortingAccessor(item) : item[property];
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
    if (changes['columnConfigs']) {
      this.columnKeys = this.columnConfigs.map(c => c.key);
    }
    if (changes['currentPage'] && this.paginator) {
      this.paginator.pageIndex = this.currentPage;
    }
    if (changes['dataSourceChange'] && this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        const column = this.columnConfigs.find(col => col.key === property);
        if (column?.sortingAccessor) {
          return column.sortingAccessor(item);
        }
        return item[property];
      };
    }
  }

  onChangedPage(event: PageEvent) {
    this.pageChanged.emit(event);
  }

  onSearch(value: string) {
    this.searchChanged.emit(value);
  }

  onAction(eventName: string, element: any) {
    this.actionEvent.emit({ eventName, element });
  }

  announceSortChange(sortState: Sort) {
    this.sortCol.emit(sortState)
  }
}
