import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  open: boolean = false;
  openNav: boolean = false;
  languages: string[] = ['english', 'french'];
  languageKeys: string[] = ["en", "fn"];
  selected = 'option2';
  currentLanguage: 'en' | 'fr' = 'en';
  elem: any;
  isfullScreen: boolean = true;
  dialogRef: MatDialogRef<any>;
  constructor(public _navService: NavService, private router: Router, private apiService: ApiService, public dialog: MatDialog, private commonService: CommonService, private translate: TranslateService, @Inject(DOCUMENT) private document: any) {
    this.currentLanguage = JSON.parse(this.commonService.getLocalStorage('currentLang'));
  }

  ngOnInit(): void {
    this.elem = document.documentElement;
  }

  /* On Language Change */
  setLanguage(lang: 'en' | 'fr') {
    this.currentLanguage = lang;
    this.commonService.setCurrentLang('currentLang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.commonService.updateLanguage(lang);
  }

  openFullscreen() {
    this.isfullScreen = false;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    this.isfullScreen = true;
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }


  onSelectionChange(event: any) {
  }

  onClick(): void {
    this.dialogRef.close('logout');
  }

  collaspeSidebar() {
    this.open = !this.open;
    this._navService.collaspeSidebar = !this._navService.collaspeSidebar;
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout(templateRef) {
    this.dialogRef = this.dialog.open(templateRef, {
      width: '300px'
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'logout') {
        this.apiService.logout();
      }
    });
  }
}
