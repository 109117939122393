<div class="container-fluid bg-color">
  <div class="bg-color">
    <mat-card>
      <div class="home-icon font-xl text-muted nomargin">
        <i class="fa fa-home bg-color-blueLight text-color-white padding-10 p_r_10 margin-10 boxshadow_well">
        </i>
        <b class="lbl_dashboard">{{ 'LBL_DASHBOARD' | translate}}</b>
      </div>
    </mat-card>
  </div>
  <div class="row bg-color mt-3">
    <!-- USERS -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-pink">
        <div class="new-dashboard-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">
              <div class="users_shade new-people-shapes-1">
              </div>
              <span class="user">{{'LBL_USERS' | translate}}</span>
            </div>
            <div class="new-dashboard-box-top-right">
              <div style="margin-top: 1px;">
                {{users?.totalItems}}
              </div>
            </div>
          </div>
          <div class="new-dash-active-user-wrapper">
            <div class="active_user row">
              <div class="col-9 pl-45">{{"LBL_DASHBORD_MASTERS" | translate}}</div>
              <div class="col-3 text-right pl-25">{{users?.admin}}</div>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_NOT_VERIFIED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{users?.notVerified}}</span>
            </div>

            <!-- <div class="active_user row">
              <span class="col-9 pl-45">SETUP</span>
              <span class="col-3 text-right pl-25">{{users?.setup}}</span>
            </div> -->
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_ACTIVE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{users?.active}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{users?.spam}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_CLOSE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{users?.close}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{users?.delete}}</span>
            </div>
            <div class="new-dashboard-box-middle row">
              <div class="new-dashboard-box-middle-left col-6">
                <div>
                  <i class="fa fa-eye" aria-hidden="true"></i>
                  <span class="user-view">{{users?.totalViews}}</span>
                </div>
                <div>
                  <i class="fa fa-heart" aria-hidden="true"></i>
                  <span class="user-view">{{users?.totalFavourite}}</span>
                </div>
              </div>
              <div class="new-dashboard-box-middle-right col-6">
                <div class="middle-right-female">
                  <i class="fa fa-female" aria-hidden="true"></i>
                  <span>{{users?.feMale}}</span>
                </div>
                <div class="middle-right-male">
                  <i class="fa fa-male" aria-hidden="true"></i>
                  <span>{{users?.male}}</span>
                </div>
              </div>
            </div>
            <div class="new-dashboard-box-bottom">
              <span class="user-online"></span>
              <div *ngIf="users?.online">{{users?.online}} {{"LBL_DASHBORD_USERS_ONLINE" | translate}} </div>
              <div *ngIf="users?.online == 0">0 {{"LBL_DASHBORD_USERS_ONLINE" | translate}} </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- COMPANYIES -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-pink">
        <div class="new-dashboard-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">
              <div class="users_shade new-company-shapes-1">
              </div>
              <span class="user">{{"LBL_COMPANY" | translate}}</span>
            </div>
            <div class="new-dashboard-box-top-right">
              <div class="company-img mt-1">
                {{company?.totalItems}}
              </div>
            </div>
          </div>
          <div class="new-dash-active-user-wrapper">

            <div class="new-dash-active-user-wrapper">
              <div class="active_user row">
                <span class="col-9 pl-45">{{"LBL_DASHBORD_SETUP" | translate}}</span>
                <span class="col-3 text-right pl-25">{{company?.setup}}</span>
              </div>
              <div class="active_user row color_pink">
                <span class="col-9 pl-45">{{"LBL_DASHBORD_ACTIVE" | translate}}</span>
                <span class="col-3 text-right pl-25">{{company?.active}}</span>
              </div>
              <div class="active_user row">
                <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
                <span class="col-3 text-right pl-25">{{company?.spam}}</span>
              </div>
              <div class="active_user row">
                <span class="col-9 pl-45">{{"LBL_DASHBORD_CLOSE" | translate}}</span>
                <span class="col-3 text-right pl-25">{{company?.close}}</span>
              </div>
              <div class="active_user row">
                <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
                <span class="col-3 text-right pl-25">{{company?.delete}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-middle">
              <div class="new-dashboard-box-middle-left">
                <div>
                  <i class="fa fa-eye" aria-hidden="true"></i>
                  <span class="user-view">{{company?.totalViews}}</span>
                </div>
                <div>
                  <i class="fa fa-heart" aria-hidden="true"></i>
                  <span class="user-view">{{company?.totalFavourite}}</span>
                </div>
              </div>
              <div class="new-dashboard-box-bottom">
                <img src="../../../assets/images/check-img.svg" style="width:15px">
                <div>{{company?.Verified}} {{"LBL_DASHBORD_VERIFIED_COMPANY" | translate}} </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- COMMUNITITES -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-purple">
        <div class="new-dashboard-box services-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">

              <div>
                <img src="../../../assets/images/hexa.png" alt="service">
                <span class="purple">{{"LBL_COMMUNITIES" | translate}}</span>
              </div>
            </div>

            <div class="new-dashboard-box-top-right">
              <div>
                {{communities?.totalItems}}
              </div>
            </div>
          </div>
          <div class="new-dash-active-user-wrapper communities">
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SHARED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{communities?.shared}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{communities?.spam}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{communities?.delete}}</span>
            </div>
          </div>
          <div class="new-dashboard-box-middle">
            <div class="new-dashboard-box-middle-left">
              <div>
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span class="user-view">{{communities?.totalViews}}</span>
              </div>
              <div>
                <i class="fa fa-heart" aria-hidden="true"></i>
                <span class="user-view">{{communities?.totalFavourite}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-middle-right">
              <div>
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                <span>{{communities?.totalLike}}</span>
              </div>
              <div>
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                <span class="ml-5">{{communities?.totalComment}}</span>
              </div>
            </div>
          </div>
          <div class="new-dashboard-box-bottom">
            <ul class="box-bottom-box-list">
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-purple"></span>
                  {{communities?.totalJoinMember}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_DASHBORD_MEMBERS_JOINED" | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- SPECIAL OFFERS -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header bg-pink">
        <div class="new-dashboard-box offers-box">
          <div class="offers-box-title">{{"LBL_DASHBORD_SPECIAL_OFFERS" | translate}}</div>
          <ul>
            <li class="active_user d-flex">
              <div class="active_user-left">{{specialOffer?.sent}}</div>
              <div class="active_user-right col-6 text-left">{{"SENT" | translate}}</div>
            </li>
            <li class="active_user d-flex">
              <div class="active_user-left">{{specialOffer?.accepted}}</div>
              <div class="active_user-right col-6 text-left">{{"LBL_ACCEPTED" | translate}}</div>
            </li>
            <li class="active_user d-flex">
              <div class="active_user-left">{{specialOffer?.skipped}}</div>
              <div class="active_user-right col-6 text-left">{{"LBL_SKIPPED" | translate}}</div>
            </li>
            <li class="active_user d-flex">
              <div class="active_user-left">{{users?.successOffersCount ? users?.successOffersCount : 0}}</div>
              <div class="active_user-right col-6 text-left">{{"LBL_CONTROLLER_SUCCESS" | translate}}</div>
            </li>
          </ul>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row bg-color mt-3">
    <!-- JOBS -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-orange3">
        <div class="new-dashboard-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">

              <div>
                <img src="../../../assets/images/job-icon.png" alt="service">
                <span class="orange">{{"LBL_JOBS" | translate}}</span>
              </div>
            </div>

            <div class="new-dashboard-box-top-right">
              <div class="totalItems">
                {{job?.totalItems}}
              </div>
            </div>
          </div>
          <div class="new-dash-active-user-wrapper jobs">
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SHARED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{job?.shared}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{job?.spam}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_EXPIRED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{job?.expire}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{job?.delete}}</span>
            </div>
          </div>
          <div class="new-dashboard-box-middle">
            <div class="new-dashboard-box-middle-left">
              <div>
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span class="user-view">{{job?.totalViews}}</span>
              </div>
              <div>
                <i class="fa fa-heart" aria-hidden="true"></i>
                <span class="user-view">{{job?.totalFavourite}}</span>
              </div>
            </div>
          </div>
          <div class="new-dashboard-box-bottom">
            <ul class="box-bottom-box-list">
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-orange"></span>
                  {{job?.totalApplication}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_APPLICATIONS" | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- SERVICE/PRODUCT -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header red-border3">
        <div class="new-dashboard-box services-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">

              <div>
                <img src="../../../assets/images/service-icon.png" alt="service">
                <span class="red">{{"LBL_SERVICE" | translate}}/<br />{{"LBL_PRODUCTS" | translate}}</span>
              </div>
            </div>

            <div class="new-dashboard-box-top-right">
              <div>
                {{service?.totalItems}}
              </div>
            </div>
          </div>
          <div style="display: inline-block; width: 100%;" class="new-dash-active-user-wrapper">
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SHARED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{service?.shared}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{service?.spam}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_EXPIRED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{service?.expire}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{service?.delete}}</span>
            </div>
          </div>
          <div class="new-dashboard-box-middle">
            <div class="new-dashboard-box-middle-left">
              <div>
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span class="user-view">{{service?.totalViews}}</span>
              </div>
              <div>
                <i class="fa fa-heart" aria-hidden="true"></i>
                <span class="user-view">{{service?.totalFavourite}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-middle-right">
              <div>
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                <span>{{service?.totalLike}}</span>
              </div>
              <div>
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                <span>{{service?.totalComment}}</span>
              </div>
            </div>
          </div>
          <div class="new-dashboard-box-bottom" style="position: relative;">
            <ul class="box-bottom-box-list">
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="red-border"></span>
                  {{service?.totalRatings}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_RATING" | translate}}
                </div>
              </li>
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="red-border"></span>
                  {{service?.serviceClickCount}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_CLICK_URL_ADDRESS" | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- SPACE -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-green">
        <div class="new-dashboard-box spaces-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">
              <div>
                <img src="../../../assets/images/space-icon.png" alt="space">
                <span class="green">{{"LBL_SPACES" | translate}}</span>
              </div>

            </div>
            <div class="new-dashboard-box-top-right">
              <div>
                {{space?.totalItems}}
              </div>
            </div>
          </div>
          <div style="display: inline-block; width: 100%;" class="new-dash-active-user-wrapper">
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SHARED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{space?.shared}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{space?.spam}}</span>
            </div>
            <!-- <div class="active_user row" style="visibility: hidden;">
              <span class="col-9 pl-45">CLOSE</span>
              <span class="col-3 text-right pl-25">{{space?.length}}</span>
            </div> -->
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{space?.delete}}</span>
            </div>
          </div>
          <div class="new-dashboard-box-middle">
            <div class="new-dashboard-box-middle-left">
              <div>
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span class="user-view">{{space?.totalViews}}</span>
              </div>
              <div>
                <i class="fa fa-heart" aria-hidden="true"></i>
                <span class="user-view">{{space?.totalFavourite}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-middle-right">
              <div>
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                <span>{{space?.totalLike}}</span>
              </div>
              <div>
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                <span>{{space?.totalComment}}</span>
              </div>
            </div>
          </div>
          <div class="new-dashboard-box-bottom" style="position: relative;">
            <ul class="box-bottom-box-list">
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-green"></span>
                  {{space?.totalRatings}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_RATING" | translate}}
                </div>
              </li>
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-green"></span>
                  {{space?.totalClickOnBook}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_CLICK_BOOK_BUTTON" | translate}}
                </div>
              </li>
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-green"></span>
                  {{space?.totalJoinMember}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_COWORKER_JOINED" | translate}}
                </div>
              </li>
            </ul>
          </div>

        </div>
      </mat-card>
    </div>
    <!-- EVENT -->
    <div class="col-lg-3 col-sm-6 col-xl-3">
      <mat-card class="main_header border-blue">
        <div class="new-dashboard-box events-box">
          <div class="new-dashboard-box-top">
            <div class="new-dashboard-box-top-left">
              <div>
                <img src="../../../assets/images/event-icon.png" alt="space">
                <span class="blue">{{"LBL_EVENTS" | translate}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-top-right">
              <div class="company-img mt-1">
                {{event?.totalItems}}
              </div>
            </div>
          </div>
          <div style="display: inline-block; width: 100%;" class="new-dash-active-user-wrapper">
            <div class="active_user row color_pink">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SHARED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{event?.shared}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_SPAM" | translate}}</span>
              <span class="col-3 text-right pl-25">{{event?.spam}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_ENDED" | translate}}</span>
              <span class="col-3 text-right pl-25">{{event?.ended}}</span>
            </div>
            <div class="active_user row">
              <span class="col-9 pl-45">{{"LBL_DASHBORD_DELETE" | translate}}</span>
              <span class="col-3 text-right pl-25">{{event?.delete}}</span>
            </div>
          </div>
          <div class="new-dashboard-box-middle">
            <div class="new-dashboard-box-middle-left">
              <div>
                <i class="fa fa-eye" aria-hidden="true"></i>
                <span class="user-view">{{event?.totalViews}}</span>
              </div>
              <div>
                <i class="fa fa-heart" aria-hidden="true"></i>
                <span class="user-view">{{event?.totalFavourite}}</span>
              </div>
            </div>
            <div class="new-dashboard-box-middle-right">
              <div>
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                <span>{{event?.totalLike}}</span>
              </div>
              <div>
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                <span>{{event?.totalComment}}</span>
              </div>
            </div>
          </div>
          <div class="new-dashboard-box-bottom" style="position: relative;">
            <ul class="box-bottom-box-list">
              <li>
                <div class="box-bottom-box-list-left">
                  <span class="border-blue"></span>
                  {{event?.totalJoinMember}}
                </div>
                <div class="box-bottom-box-list-right">
                  {{"LBL_ALL_INTERESTED_MEMBERS" | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row bg-color mt-3">
    <div class="col-md-6">
      <mat-card class="graph_header">
        <h5 class="margin-top-0">
          <i class="fa fa-users font-md"></i>
          <b>{{ 'LBL_GRAPH' | translate}}</b>
        </h5>
        <div ng-show="monthWiseIncome.length == 0" class="text-center nodata_on_graph_income">
          <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
        </div>
        <div class="nopadding" ng-show="monthWiseIncome.length !== 0">
          <canvas id="myChart" height="218"></canvas>
        </div>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="graph_header">

        <h5>
          <i class="fa fa-users font-md"></i>
          <b>{{ 'LBL_GRAPH_LOCATION_USERS' | translate}}</b>
        </h5>
        <div class="location_dropdown">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" placeholder="{{'LBL_TITLE_COUNTRY' | translate}}"
              (change)="onChangeCountry($event.target.value)" *ngIf="countries && countries.length > 0">
              <option>{{'LBL_TITLE_COUNTRY' | translate}}</option>
              <option [value]="country._id" *ngFor="let country of countries">
                {{country?.name?.en}}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" placeholder="State"
              (change)="onChangeState($event.target.value)">
              <option>{{"LBL_TITLE_STATE" | translate}}</option>
              <option [value]="state._id" *ngFor="let state of stateData">{{ state.name }}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" ng-disabled="cityflag" placeholder="City"
              ng-model="addressdata.city" name="city" ng-options="ct as ct.name for ct in cities"
              ng-change="selectCity(addressdata.city)">
              <option value="" selected="" disabled="">{{ 'LBL_TITLE_CITY' | translate }}</option>
            </select>
          </div>
        </div>
        <div ng-show="markers.length == 0" class="text-center nodata_on_graph">
          <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
        </div>
        <!-- <div class="nopadding" ng-show="markers.length > 0">
                      <ui-gmap-google-map id="event_right_map" center='map.center' zoom='map.zoom'>
                          <ui-gmap-marker ng-repeat="map in markers"
                              coords="{ latitude: map.latitude, longitude: map.longitude }" idkey="'map.event_id'"
                              title="map.title">
                              <ui-gmap-window show="showWindow">
                                  <div><a href="#/event/detail/{{map.event_id}}">{{map.title}}</a></div>
                              </ui-gmap-window>
                          </ui-gmap-marker>
                      </ui-gmap-google-map>
                  </div> -->
      </mat-card>
    </div>
  </div>
  <div class="row bg-color mt-3">
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
          <h5 class="margin-top-0">
            <i class="fa fa-users font-md"></i>
            <b>{{"LBL_GRAPH_LOCATION_COMPANIES" | translate}}</b>
          </h5>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin location_dropdown">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" placeholder="{{'LBL_TITLE_COUNTRY' | translate}}"
              (change)="onChangeCountry($event.target.value)" *ngIf="countries && countries.length > 0">
              <option>{{'LBL_TITLE_COUNTRY' | translate}}</option>
              <option [value]="country?._id" *ngFor="let country of countries">{{country?.name?.en}}
              </option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" placeholder="State"
              (change)="onChangeState($event.target.value)">
              <option>{{"LBL_TITLE_STATE" | translate}}</option>
              <option [value]="state._id" *ngFor="let state of stateData">{{ state.name }}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
            <select class="border-radius-6 noborder form-control" ng-disabled="cityflag" placeholder="City"
              ng-model="addressdata_company.city" name="city" ng-options="ct as ct.name for ct in cities"
              ng-change="selectCity_company(addressdata_company.city)">
              <option value="city" selected="" disabled="">{{ 'LBL_TITLE_CITY' | translate }}</option>
            </select>
          </div>
        </div>
        <div ng-show="markers_company.length == 0" class="text-center nodata_on_graph">
          <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
        </div>
        <!-- <div class="nopadding" ng-show="markers_company.length > 0">
                  <ui-gmap-google-map id="event_right_map" center='map.center' zoom='map.zoom'>
                      <ui-gmap-marker ng-repeat="map in markers_company"
                          coords="{ latitude: map.latitude, longitude: map.longitude }" idkey="'map.event_id'"
                          title="map.title">
                          <ui-gmap-window show="showWindow">
                              <div><a href="#/event/detail/{{map.event_id}}">{{map.title}}</a></div>
                          </ui-gmap-window>
                      </ui-gmap-marker>
                  </ui-gmap-google-map>
  
              </div> -->
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
            <h5 class="margin-top-0">
              <i class="fa fa-users font-md"></i>
              <b>{{"LBL_GRAPH_LOCATION_SPACES" | translate }}</b>
            </h5>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin location_dropdown">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="{{'LBL_TITLE_COUNTRY' | translate}}"
                (change)="onChangeCountry($event.target.value)" *ngIf="countries && countries.length > 0">
                <option>{{'LBL_TITLE_COUNTRY' | translate}}</option>
                <option [value]="country._id" *ngFor="let country of countries">
                  {{country.name.en}}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="State"
                (change)="onChangeState($event.target.value)">
                <option>{{"LBL_TITLE_STATE" | translate}}</option>
                <option [value]="state._id" *ngFor="let state of stateData">{{ state.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" ng-disabled="cityflag" placeholder="City"
                ng-model="addressdata_space.city" name="city" ng-options="ct as ct.name for ct in cities"
                ng-change="selectCity_space(addressdata_space.city)">
                <option value="" selected="" disabled="">{{ 'LBL_TITLE_CITY' | translate }}</option>
              </select>
            </div>
          </div>
          <div ng-show="markers_space.length == 0" class="text-center nodata_on_graph">
            <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
          </div>
          <!-- <div class="nopadding" ng-show="markers_space.length > 0">
            <ui-gmap-google-map id="event_right_map" center='map.center' zoom='map.zoom'>
              <ui-gmap-marker ng-repeat="map in markers_space"
                coords="{ latitude: map.latitude, longitude: map.longitude }" idkey="'map.event_id'" title="map.title">
                <ui-gmap-window show="showWindow">
                  <div><a href="#/event/detail/{{map.event_id}}">{{map.title}}</a></div>
                </ui-gmap-window>
              </ui-gmap-marker>
            </ui-gmap-google-map>
          </div> -->
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row bg-color" style="margin-top: 30px;">
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
            <h5 class="margin-top-0">
              <i class="fa fa-users font-md"></i>
              <b>{{"LBL_GRAPH_LOCATION_EVENTS" | translate}}</b>
            </h5>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin location_dropdown">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="{{'LBL_TITLE_COUNTRY' | translate}}"
                (change)="onChangeCountry($event.target.value)" *ngIf="countries && countries.length > 0">
                <option>{{'LBL_TITLE_COUNTRY' | translate}}</option>
                <option [value]="country._id" *ngFor="let country of countries">{{country.name.en}}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="State"
                (change)="onChangeState($event.target.value)">
                <option>{{"LBL_TITLE_STATE" | translate}}</option>
                <option [value]="state._id" *ngFor="let state of stateData">{{ state.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" ng-disabled="cityflag" placeholder="City"
                ng-model="addressdata_event.city" name="city" ng-options="ct as ct.name for ct in cities"
                ng-change="selectCity_event(addressdata_event.city)">
                <option>{{ 'LBL_TITLE_CITY' | translate }}</option>
                <option value="city" *ngFor="let city of cities" selected="selectedCity">{{ 'LBL_TITLE_CITY' | translate
                  }}
                </option>
              </select>
            </div>
          </div>
          <div ng-show="markers_event.length == 0" class="text-center nodata_on_graph">
            <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
          </div>
          <!-- <div class="nopadding" ng-show="markers_event.length > 0">
              <ui-gmap-google-map id="event_right_map" center='map.center' zoom='map.zoom'>
                  <ui-gmap-marker ng-repeat="map in markers_event"
                      coords="{ latitude: map.latitude, longitude: map.longitude }" idkey="'map.event_id'"
                      title="map.title">
                      <ui-gmap-window show="showWindow">
                          <div><a href="#/event/detail/{{map.event_id}}">{{map.title}}</a></div>
                      </ui-gmap-window>
                  </ui-gmap-marker>
              </ui-gmap-google-map>
          </div> -->
        </div>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
            <h5 class="margin-top-0">
              <i class="fa fa-users font-md"></i>
              <b>{{"LBL_GRAPH_LOCATION_COMMUNITY" | translate}}</b>
            </h5>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin location_dropdown">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="{{'LBL_TITLE_COUNTRY' | translate}}"
                (change)="onChangeCountry($event.target.value)" *ngIf="countries && countries.length > 0">
                <option>{{'LBL_TITLE_COUNTRY' | translate}}</option>
                <option [value]="country._id" *ngFor="let country of countries">{{country.name.en}}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="State"
                (change)="onChangeState($event.target.value)">
                <option>{{"LBL_TITLE_STATE" | translate}}</option>
                <option [value]="state._id" *ngFor="let state of stateData">{{ state.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-5">
              <select class="border-radius-6 noborder form-control" placeholder="City">
                <option>City</option>
                <option value="" selected="" disabled="">{{ 'LBL_TITLE_CITY' | translate }}</option>
              </select>
            </div>
          </div>
          <div ng-show="markers_group.length == 0" class="text-center nodata_on_graph">
            <strong class="margin-left-5">{{"LBL_NO_DATA_FOUND" | translate}}</strong>
          </div>
          <!-- <div class="nopadding" ng-show="markers_group.length > 0">
                  <ui-gmap-google-map id="event_right_map" center='map.center' zoom='map.zoom'>
                      <ui-gmap-marker ng-repeat="map in markers_group"
                          coords="{ latitude: map.latitude, longitude: map.longitude }" idkey="'map.group_id'"
                          title="map.title">
                          <ui-gmap-window show="showWindow">
                              <div><a href="#/event/detail/{{map.group_id}}">{{map.title}}</a></div>
                          </ui-gmap-window>
                      </ui-gmap-marker>
                  </ui-gmap-google-map> -->
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row bg-color" style="margin-top: 30px;">
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
            <h5 class="margin-top-0">
              <i class="fa fa-users font-md"></i>
              <b>{{"LBL_AVERAGE_DURATIION" | translate}}</b>
            </h5>
          </div>
          <!-- <div class="text-center nodata_on_graph">
                  <strong class="margin-left-5">No data found</strong>
              </div> -->
          <div class="new-average-box">
            <ul>
              <li>
                <div class="new-average-box-left">{{"LBL_A_JOB_SHARED_ON_LINE_DURING_AVERAGE_OF" | translate}}</div>
                <div class="new-average-box-right">2 {{"LBL_WEEKS" | translate}}</div>
              </li>
              <li>
                <div class="new-average-box-left">{{"LBL_A_EVENT_JOB_SHARED_ON_LINE_DURING_AVERAGE_OF" | translate}}
                </div>
                <div class="new-average-box-right">6 {{"LBL_MONTHS" | translate}}</div>
              </li>
              <li>
                <div class="new-average-box-left">{{"LBL_AVERAGE_HOUR_WHEN_USER_CLICK_ON_BOOK_BUTTON" | translate}}
                </div>
                <div class="new-average-box-right"> 10% {{"LBL_MORNING" | translate}}<br>
                  20% {{"LBL_AFTERNOON" | translate}}<br>
                  70% {{"LBL_EVENNING" | translate}}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card class="graph_header">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding no-margin">
          <h5 class="margin-top-0">
            <i class="fa fa-users font-md"></i>
            <b>{{"LBL_USER_AVERAGE" | translate}}</b>
          </h5>
        </div>
        <!-- <div class="text-center nodata_on_graph">
                  <strong class="margin-left-5">No data found</strong>
              </div> -->
        <div class="new-average-box">
          <ul>
            <li>
              <div class="new-average-box-left">{{"LBL_HOW_OLD_USERS_IN_AVERAGE" | translate}}</div>
              <div class="new-average-box-right">30 {{"LBL_YEAR_OLD" | translate}}</div>
            </li>
            <li>
              <div class="new-average-box-left">{{"LBL_HOW_MANY_WOMEN" | translate}}</div>
              <div class="new-average-box-right">30%</div>
            </li>
            <li>
              <div class="new-average-box-left">{{"LBL_HOW_MANY_MEN" | translate}}</div>
              <div class="new-average-box-right">70%</div>
            </li>
          </ul>
        </div>
      </mat-card>
    </div>
  </div>
</div>