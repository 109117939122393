import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {
  public user = new BehaviorSubject<{}>({});

  constructor() { }

  sendUserDetail(user: any) {
    this.user.next(user)
  }

  getValue() {
    return this.user.asObservable();
  }
}
