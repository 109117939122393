import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './components/auth/auth.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { HeaderInterceptors } from './interceptors/header.interceptors';
import { AppRoutingModule } from './app-routing.module';
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSpinnerComponent } from './shared/components/mat-spinner/mat-spinner.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

const config: SocketIoConfig = {
  url: environment.SOCKET_URL, // socket server url;
  options: {
    transports: ['websocket'],
  },
};
@NgModule({
  declarations: [
    AppComponent, MatSpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    DashboardModule,
    AuthModule,
    HttpClientModule,
    NgDynamicBreadcrumbModule,
    CKEditorModule,
    MatProgressSpinnerModule,
    SocketIoModule.forRoot(config),

    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot()
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true
    // }
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptors, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}