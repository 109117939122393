// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  BASE_URL: 'http://localhost:4200/',
  // IMAGE_PATH: 'https://newjbfdev.s3.eu-west-3.amazonaws.com/', // Development URL
  // // API_BASE_URL: 'https://jbfapiv3.justbeflex.com/', // live URL
  // API_BASE_URL: 'http://jbf-be.sctestinglab.com/', // Development URL
  // // SOCKET_URL: 'https://jbfapiv3.justbeflex.com', // live URL
  // SOCKET_URL: 'http://jbf-be.sctestinglab.com', // Development URL

  //Pre release URLs
  // IMAGE_PATH: 'https://jbfprodimages.s3.eu-west-3.amazonaws.com/',
  // API_BASE_URL: 'https://jbfapiv3.sctestingsite.com/',
  // SOCKET_URL: 'https://jbfapiv3.sctestingsite.com',
  //Pre release URLs

  //Pre release URLs Client
  // IMAGE_PATH: 'https://jbfprodimages.s3.eu-west-3.amazonaws.com/',
  // API_BASE_URL: 'https://jbfapipr.justbeflex.com/',
  // SOCKET_URL: 'https://jbfapipr.justbeflex.com',
  //Pre release URLs Client

  //Production URL Client
  IMAGE_PATH: 'https://jbfprodimages.s3.eu-west-3.amazonaws.com/',
  API_BASE_URL: 'https://api.justbeflex.com/',
  SOCKET_URL: 'https://api.justbeflex.com',
  //Production URL Client

  API_ENDPONT_WELLCOME_LIST: 'api/welcomePopUp/welcomePopUpAdminList',
  API_ENDPOINT_CONTACTUS: 'api/contactUs/contactUsList',
  API_ENDPONT_CONTACTUS_UPDATE: 'api/contactUs/contactUsUpdate',
  API_ENDPOINT_WELLCOME_POPUP: 'api/welcomePopUp/createWelcomePopUp',
  API_ENDPOINT_FEED_LIST: 'api/feed/feedAccountList',
  API_ENDPOINT_FEED_LIST_ADD: 'api/feed/addFeed',
  API_ENDPOINT_WELLCOME_IMAGE: 'api/welcomePopUp/welcomePopUpDetails',
  API_ENDPOINT_FEED_LIST_IMAGE: 'api/feed/detailsFeed',
  API_ENDPONT_COMMEN_NAME_LIST: 'api/commonName/commonNameLists',
  API_ENDPOINT_SPACE_LIST: 'api/space/spaceList',
  API_ENDPOINT_FEED_LIST_ACTIVE: 'api/feed/activeDeactiveFeed',
  API_ENDPOINT_FEED_LIST_DELETE: 'api/feed/feedDelete',
  LOGIN_USER: 'auth/local/admin',
  OFFER_LIST: 'api/offer/offerAccountList',
  COMMEN_NAME_LIST: 'api/commonName/commonNameLists',
  BASIC_USER_LIST: 'api/users/userList',
  ALL_COMPANY: 'api/company/companyList',
  ADD_OFFER: 'api/offer/addoffer',
  DELETE_OFFER: 'api/offer/offerDelete',
  DELETE_COMPANY_ROLES: 'api/role/roleDelete',
  DELETE_COMMUNITY_ROLES: 'api/role/roleDelete',
  ACTIVE_DEACTIVE_OFFER: 'api/offer/activeDeactiveOffer',
  NOTIFY_OFFER: 'api/offer/setOfferNotify',
  USERS_LIST: 'api/users/usersAccountList',
  API_ENDPOINT_FEED_LIST_SEND: 'api/feed/setFeedNotify',
  PLAN_LIST: 'api/plan/getPlanAllList',
  API_ENDPOINT_GET_IDWISE_PLANDETAILS: 'api/plan/getPlanDetail/',
  API_ENDPOINT_UPDATE_PLANDETAILS: 'api/plan/updatePlan',
  API_ENDPONT_MEMBER_TYPE_LIST: 'api/commonName/commonNameLists',
  API_ENDPONT_DELETE_MEMBER_TYPE: 'api/commonName/commonNameDelete',
  API_ENDPONT_ADD_MEMBER_TYPE: 'api/commonName/commonNameAdd',
  API_ENDPONT_ADD_MASTER_ROLES: 'api/users/createAdmin',
  API_ENDPOINT_UPDATE_MEMBER_TYPE: 'api/commonName/commonNameUpdate',
  API_ENDPOINT_UPDATE_MASTER_ROLES: 'api/users/updateUser',
  API_ENDPOINT_GET_IDWISE_MEMBERTYPE_DETAILS:
    'api/commonName/commonNameDetails/',
  API_ENDPOINT_GET_IDWISE_MASTER_ROLES_DETAILS: 'api/users/userDetail',
  API_ENDPOINT_ADD_FREEMINUMLIMIT: 'api/plan/addFreeminumLimit',
  API_ENDPOINT_ADD_VAT: 'api/plan/addVat',
  API_ENDPOINT_GET_FREEMINUMLIMIT: 'api/plan/getFreemiumVat',
  API_ENDPOINT_DELETE_MASTER_ROLE: 'api/users/updateUser',

  COMPANY_ROLES: 'api/role/roleList',
  GET_SHARED_JOBS: 'api/job/jobListAdmin',
  GET_EXPIRED_JOBS: 'api/job/jobListAdmin',
  GET_DELETED_JOBS: 'api/job/jobListAdmin',
  GET_SPAM_JOBS: 'api/job/jobListAdmin',
  UPDATE_SPAM_JOBS: 'api/job/updateJob',
  GET_ALL_JOB_APPLICATIONS: 'api/job/jobApplicantAdmin',
  DELETE_JOB_APPLICATION: 'api/job/updateJobApplication',
  DELETE_SPAM_JOB: 'api/job/updateJob',
  REACTIVE_DELETE_JOB: 'api/job/updateJob',
  REACTIVE_DELETE_JOB_APPLICATION: 'api/job/updateJobApplication',
  GET_DELETED_APPLICATIONS: 'api/job/jobApplicantAdmin',
  COMMUNITY_ROLES: 'api/role/roleList',
  CREATE_COMPANY_ROLES: 'api/role/createRole',
  CREATE_COMMUNITY_ROLES: 'api/role/createRole',
  INVOICE_LIST: 'api/transaction/transactionAdminList',
  GET_COMMUNITIES: 'api/community/communityAccountListNew',
  COMMUNITIES_UPDATE: 'api/community/communityUpdate',
  API_ENDPOINT_CREATE_FANDQ: 'api/faq/createFaq',
  API_ENDPOINT_FANDQ_DELETE: 'api/faq/faqDelete',
  API_ENDPOINT_FANDQ_UPDATE: 'api/faq/updateFaq',
  API_ENDPOINT_FANDQ_GET_LIST: 'api/faq/faqList',
  API_ENDPOINT_FANDQ_GET: 'api/faq/faqDetails',
  API_ENDPOINT_SUBSCRIBER_LIST: 'api/subscriber/subscriberLists',
  API_ENDPOINT_SUBSCRIBER_DELETE: 'api/subscriber/subscriberDelete',
  API_ENDPOINT_COMPANY_VERIFICATION_LIST: 'api/company/companyVerifactionList',
  API_ENDPOINT_COMPANY_VERIFICATION_UPDATE: 'api/company/companyUpdate',
  API_ENDPOINT_COMPANY_VERIFICATION_DETAILS:
    'api/company/companyProfileDetails',
  API_ENDPOINT_USER_UPDATE: 'api/users/updateUser',
  API_ENDPOINT_USER_REVERIFIED: 'api/users/reVerification',
  API_ENDPOINT_BUSSINESS_LIST: 'api/company/businessAccountList',
  COMMON_NAME_LISTS: 'api/commonName/commonNameLists',
  COMPANY_DETAILS: 'api/company/basicAndNonBlockCompany',
  ALL_PREMIUM_MEMBER: 'api/users/premiumAndNonBlockUser',
  GET_CATEGORY_TYPE: 'api/categoryType/categoryTypeLists',
  DELETE_CATEGORY_TYPE: 'api/categoryType/categoryTypeDelete',
  GET_COMMON_NAME_LIST: 'api/commonName/commonNameLists',
  ADD_CATEGORY_TYPE: 'api/categoryType/categoryTypeAdd',
  GET_IDWISE_CATEGORYTYPE_DETAILS: 'api/categoryType/categoryTypeDetails',
  UPDATE_CATEGORY_TYPE: 'api/categoryType/categoryTypeUpdate',
  GET_CATEGORY_SKILLS: 'api/categorySkills/categorySkillsLists',
  DELETE_CATEGORY_SKILLS: 'api/categorySkills/categorySkillsDelete',
  ADD_CATEGORY_SKILLS: 'api/categorySkills/categorySkillsAdd',
  UPDATE_CATEGORY_SKILLS: 'api/categorySkills/categorySkillsUpdate',
  GET_IDWISE_CATEGORY_SKILLS_DETAILS:
    'api/categorySkills/categorySkillsDetails',
  DELETE_LANGUAGE: 'api/commonName/commonNameDelete',
  ADD_COMMAN_NAME: 'api/commonName/commonNameAdd',
  GET_IDWISE_COMMAN_NAME_DETAILS: 'api/commonName/commonNameDetails',
  UPDATE_COMMAN_NAME: 'api/commonName/commonNameUpdate',
  GET_LANGUAGE_SKILLS: 'api/languageSkill/languageSkillLists',
  DELETE_LANGUAGE_SKILLS: 'api/languageSkill/languageSkillDelete',
  ADD_LANGUAGE_SKILLS: 'api/languageSkill/languageSkillAdd',
  GET_IDWISE_LANGUAGE_SKILLS_DETAILS: 'api/languageSkill/languageSkillDetails',
  GET_IDWISE_UPDATE_LANGUAGE_SKILLS: 'api/languageSkill/languageSkillUpdate',
  GET_THEME_TYPE: 'api/themeType/themeTypeLists',
  DELETE_THEME_TYPE: 'api/themeType/themeTypeDelete',
  ADD_THEME_TYPE: 'api/themeType/themeTypeAdd',
  GET_IDWISE_THEME_TYPE_DETAILS: 'api/themeType/themeTypeDetails',
  UPDATE_THEME_TYPE: 'api/themeType/themeTypeUpdate',
  API_ENDPOINT_GET_IDWISE_ROLES_DETAILS: 'api/role/roleDetail',
  API_ENDPOINT_UPDATE_ROLE: 'api/role/roleUpdate',
  GET_THEME_MODE: 'api/themeMode/themeModeAllLists',
  DELETE_THEME_MODE: 'api/themeMode/themeModeDelete',
  ADD_THEME_MODE: 'api/themeMode/themeModeAdd',
  GET_IDWISE_THEME_MODE_DETAILS: 'api/themeMode/themeModeDetails',
  UPDATE_THEME_MODE: 'api/themeMode/themeModeUpdate',
  GET_USERS_DASHBOARD: 'api/users/adminDashboard',
  GET_SERVICE_API: 'api/service/serviceList',
  GET_SERVICES_PRODUCTS: 'api/service/serviceAccountList',
  UPDATE_SERVICES: 'api/Service/updateService',
  GET_SERVICE_CATEGORY: 'api/Service/ServiceCategory',
  GET_COUNTRYLIST: 'api/query/Country',
  GET_STATELIST: 'api/query/State',
  // GET_SPACE_OF_TYPE: 'api/space/spaceList',
  // UPDATE_SPACE_OF_TYPE: 'api/space/spaceList',
  // ADD_SPACE_CATEGORY: 'api/SpaceCategory/spaceCategoryAdd',
  // GET_SPACE_CATEGORYLIST: 'api/SpaceCategory/spaceCategoryLists',
  // GET_SPACE_CATEGORY_IDWISE_DETAILS: 'api/SpaceCategory',
  // UPDATE_SPACE_CATEGORY: 'api/SpaceCategory/spaceCategoryUpdate',
  // GET_SPACE_FACILITY: 'api/space/spaceFacility',
  // UPDATE_SPACE_FACILITY: 'api/space/updateSpaceFacility',
  GET_EVENTS_LIST: 'api/event/eventListAdmin',
  EVENT_UPDATE: 'api/event/eventUpdate',
  SPACE_CATEGORY_TYPE: 'api/SpaceCategory/spaceCategoryLists',
  PRIVACY_POLICY_API: 'api/privacy/privacy',
  CREATE_POLICY_API: 'api/privacy/createPolicy',
  GET_POLICY_DETAIL: 'api/privacy/policyDetails',
  UPDATE_POLICY_DETAIL: 'api/privacy/updatePolicy',
  GET_JOIN_MEMBER_LIST: 'api/query/execute/conditions/JoinMember',
  UPDATE_USER_PROFILE: 'api/users/profileDetail',
  ADMIN_SPACE_LIST: 'api/space/spaceListAdmin',
  UPDATE_SPACE_LIST: 'api/Space/updateSpaceByAdmin',
  STATISTICS_PLAN_DATA: 'api/users/statisticsPlanData',
  GET_EXPERIENCE_LIST: 'api/query/execute/conditions/CommonExperience',
  UPDATE_EXPERIENCE: 'api/query/CommonExperience',
  DELETE_EXPERIENCE: 'api/query/soft/CommonExperience',
  GET_CATEGORY_WAY_LIST: 'api/query/execute/conditions/CategoryWay',
  UPDATE_CATEGORY_WAY: 'api/query/CategoryWay',
  DELETE_CATEGORY_WAY: 'api/query/soft/CategoryWay',
  TERMS_API: 'api/privacy/terms',
  GET_INTEREST_SKILL_LIST: "api/themeTypeSkill/themeTypeSkillAllLists",
  ADD_INTEREST_SKILL: "api/themeTypeSkill/themeTypeSkillAdd",
  UPDATE_INTEREST_SKILL_LIST: "api/themeTypeSkill/themeTypeSkillUpdate",
  DELETE_INTEREST_SKILL_LIST: "api/themeTypeSkill/themeTypeSkillDelete",
  GET_INTEREST_SKILL_DETAILS: "api/themeTypeSkill/themeTypeSkillDetails",
  CHANGE_PASSWORD: "api/users/changePassword",
  GET_SPONTANEOUS_JOB_APPLICATIONS: "api/company/companySpontaneousApplyListAdmin",
  DELETE_SPONTANEOUS_APPLICATION: "api/company/updateCompanySpontaneousJob",
  GET_DELETED_SPONTANEOUS_APPLICATIONS: "api/company/companySpontaneousApplyListAdmin",
  GET_CONDITION_COMMONLIST: "api/query/execute/conditions/CommonName"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
