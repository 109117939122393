<!-- <div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body">
              <img src="assets/images/Logo_2.png">
              <form [formGroup]="loginForm" (ngSubmit)="OnSubmit()">
                <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="account-tab">
                  <div class="form-group">
                    <input type="email" class="form-control" formControlName="email" placeholder="Username"
                      [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                      <div *ngIf="email.errors?.['required']">
                        This field is required.
                      </div>
                      <div *ngIf="email.errors?.['minlength']">
                        This field must have at least 1 character.
                      </div>
                      <div
                        *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
                        Please enter valid email.
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control" formControlName="password" placeholder="Password"
                      [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                      <div *ngIf="password.errors?.['required']">
                        This field is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-button">
                    <button class="btn btn-primary" type="submit">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="Container">
  <div class="row">
    <div class="col-sm-4 offset-4">
      <div class="">
        <div class="well1">
          <div class="card mb-0">
            <form [formGroup]="loginForm">
              <div class="card-header ṭext-center">
                <img src="assets/images/Logo_2.png">
              </div>
              <div class="text-center p-4">
                <li>
                  <div class="media align-items-center">
                    <button mat-stroked-button [matMenuTriggerFor]="menu" class="custom-button">
                      {{ currentLanguage }}
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="setLanguage('en')" [class.selected]="currentLanguage === 'en'">
                        EN
                      </button>
                      <button mat-menu-item (click)="setLanguage('fr')" [class.selected]="currentLanguage === 'fr'">
                        FR
                      </button>
                    </mat-menu>
                  </div>
                </li>
              </div>
              <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="account-tab">
                <div class="row">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"LBL_USERNAME" | translate}} </mat-label>
                    <input matInput type="email" formControlName="email" placeholder="{{'LBL_USERNAME' | translate}}">
                    <mat-icon aria-hidden="false" class="icon-append" fontIcon="person"></mat-icon>
                    <mat-error
                      *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)"
                      class="invalid-feedback">
                      <div *ngIf="loginForm.get('email').errors?.['required']">
                        {{"ERROR_VALIDATION" | translate}}
                      </div>
                      <div
                        *ngIf="!loginForm.get('email').errors?.['required'] && loginForm.get('email').errors?.['emailValidator']">
                        {{"ERROR_EMAIL" | translate}}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field appearance="outline">
                    <mat-label> {{"LBL_PASSWORD" | translate}} </mat-label>
                    <input matInput type="password" formControlName="password"
                      placeholder="{{'LBL_PASSWORD' | translate}}">
                    <mat-icon aria-hidden="false" class="icon-append" fontIcon="lock"></mat-icon>
                    <mat-error
                      *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)"
                      class="invalid-feedback">
                      <div *ngIf="loginForm.get('password').errors?.['required']">
                        {{"ERROR_VALIDATION" | translate}}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">{{"LBL_STAY_SIGNIN" | translate}}</label>
                </div>

                <hr>
                <div class="form-button text-center">
                  <button class="btn btn-primary" type="submit" (click)="OnSubmit()" [disabled]="isLogin">{{"LBL_SIGNIN"
                    |
                    translate}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>