import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FeatherIconComponent } from './components/feather-icon/feather-icon.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { MaterialModule } from './services/material/material.module';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import { MatSelectPatchDirective } from './mat-select-patch.directive';
import { CustomeTableComponent } from './components/custome-table/custome-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from 'ngx-ckeditor';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

registerLocaleData(localeFr);

@NgModule({
  declarations: [BreadcrumbComponent, FooterComponent, HeaderComponent, SidebarComponent, FeatherIconComponent, ContentLayoutComponent, MatSelectPatchDirective, MatSelectPatchDirective, CustomeTableComponent, CustomDatePipe],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    NgDynamicBreadcrumbModule,
    MaterialModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    MatTableModule,
    MatProgressSpinnerModule,    
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  exports: [FeatherIconComponent, BreadcrumbComponent, MaterialModule,CustomeTableComponent, CustomDatePipe, MatSelectPatchDirective]
})
export class SharedModule { }
