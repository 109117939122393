<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
        (matSortChange)="announceSortChange($event)">
        <ng-container *ngFor="let column of columnConfigs ;" [matColumnDef]="column.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ column.header | translate }}
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <ng-container *ngIf="!column.cellTemplate">
                    <span *ngIf="column.index">{{ (currentPage)*10 + (i + 1) }}</span>
                    <span *ngIf="column.isHtml" [innerHTML]="element[column.key]"></span>
                    <span *ngIf="column.isBulletList">
                        <ul class="bullet-list">
                            <li *ngFor="let data of element[column.key]">{{ data[currentLanguage] }}</li>
                        </ul>
                    </span>
                    <span *ngIf="column.isArray">
                        <ul class="bullet-list">
                            <li *ngFor="let data of element[column.key]">{{ data }}</li>
                        </ul>
                    </span>
                    <span *ngIf="column.isKeyValue">
                        <ul class="bullet-list">
                            <li *ngFor="let data of element[column.key] | keyvalue">{{ data.key }}: {{ data.value }}
                            </li>
                        </ul>
                    </span>
                    <span [ngClass]="column.isDate ? 'color-pink' : '' "
                        *ngIf="!column.isHtml && !column.isBulletList && !column.isKeyValue && !column.isArray && !column.index">
                        {{ element[column.key] }}
                    </span>
                </ng-container>
                <ng-container *ngIf="column.cellTemplate">
                    <ng-container
                        *ngTemplateOutlet="column.cellTemplate; context: { $implicit: element }"></ng-container>
                </ng-container>
            </td>
        </ng-container>

        <ng-container *ngIf="customActions.length > 0" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element" class="button-container">
                <div *ngFor="let action of customActions">
                    <button *ngIf="action.eventName == 'delete'" (click)="onAction(action.eventName, element)"
                        class="btn btn-default btn-outline-dark btn-sm" matTooltip="{{'LBL_DELETE' | translate}}"
                        aria-label="Button that shows a red tooltip" (click)="delete(element._id)">
                        <span class="fa fa-trash"></span>
                    </button>

                    <div (click)="onAction(action.eventName, element)"
                        *ngIf="element.targetStatus?.length === 0 && action.eventName == 'toggleActive'">
                        <button type="button" class="btn btn-dark btn-sm"
                            [disabled]="element.targetStatus?.length === 0">
                            <i class="fa fa-ban" aria-hidden="true"></i></button>
                    </div>
                    <div (click)="onAction(action.eventName, element)"
                        *ngIf="element.targetStatus?.length >= 1 && action.eventName == 'toggleActive'">
                        <button [class.btn-primary]="element.isDeactive" [class.btn-dark]="!element.isDeactive"
                            class="btn btn-sm" matTooltip="{{ element.isDeactive ? 'Activate' : 'Deactivate' }}">
                            <div *ngIf="element.isDeactive; else elseblock">
                                <i class="fa fa-check"></i>
                            </div>
                            <ng-template #elseblock>
                                <i class="fa fa-ban" aria-hidden="true"></i>
                            </ng-template>
                        </button>
                    </div>

                    <button (click)="onAction(action.eventName, element)" class="btn btn btn-secondary btn-sm"
                        *ngIf="action.eventName == 'send'"
                        [disabled]="element.targetStatus?.length === 0 || !element.isDeactive" matTooltip="Send">
                        <i class="fa fa-send-o"></i>
                    </button>

                    <button (click)="onAction(action.eventName, element)" class="btn btn btn-secondary btn-sm"
                        *ngIf="action.eventName == 'sendFromFeedList'" matTooltip="Send">
                        <i class="fa fa-send-o"></i>
                    </button>

                    <button (click)="onAction(action.eventName, element)" *ngIf="action.eventName == 'pdfView'"
                        type="button" class="btn btn-default pdf-icon" (click)="pdfView.emit(element)">
                        <i class="fa fa-file" aria-hidden="true"></i>
                    </button>

                    <button (click)="onAction(action.eventName, element)" *ngIf="action.eventName == 'pdfDownload'"
                        type="button" class="btn btn-default upload-icon" (click)="upload.emit(element)">
                        <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
                    </button>

                    <div
                        *ngIf="action.eventName == 'rejected' || action.eventName == 'replied' || action.eventName == 'pending'">
                        <button (click)="onAction('rejected', element)" class="btnHover mb5"
                            *ngIf=" action.eventName !== 'rejected'">{{'REJECTED'|
                            translate}}</button>
                        <button (click)="onAction('replied', element)" class="btnHover mb5"
                            *ngIf=" action.eventName !== 'replied'">{{'REPLIED'|
                            translate}}</button>
                        <button (click)="onAction('pending', element)" class="btnHover mb5"
                            *ngIf=" action.eventName !== 'pending'">{{'PENDING'|
                            translate}}</button>
                    </div>

                    <button *ngIf=" action.eventName == 'popUp'  " type="button"
                        class="btn btn-default btn-outline-dark btn-sm" (click)="onAction(action.eventName, element)">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>

                    <button *ngIf="action.eventName == 'toggle' " class=" activeAndDeactivebtn btn btn-sm"
                        [class.btn-success]="!element.isDeactive" [class.btn-danger]="element.isDeactive"
                        matTooltip="{{ element.isDeactive ? ('LBL_DEACTIVE' | translate) : ('LBL_ACTIVE' | translate) }}"
                        (click)="onAction(action.eventName, element)" matTooltipClass="example-tooltip-red"
                        aria-label="Button that shows a red tooltip">
                        <div *ngIf="!element.isDeactive; else elseblock">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <ng-template #elseblock>
                            <div>
                                <i class="fa fa-ban" aria-hidden="true"></i>
                            </div>
                        </ng-template>
                    </button>

                    <button class="btn btn-outline-info btn-sm" *ngIf="action.eventName == 'edit' "
                        (click)="onAction(action.eventName, element)">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>

                    <button mat-raised-button color="primary" *ngIf="action.eventName == 'spam' "
                        (click)="onAction(action.eventName, element)">{{'LBL_SPAM' |
                        translate}}</button>

                    <button mat-raised-button color="primary" *ngIf="action.eventName == 'reactive' "
                        (click)="onAction(action.eventName, element)">{{'LBL_REACTIVE'|
                        translate}}</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnKeys.concat(customActions.length > 0 ? ['actions'] : [])"></tr>
        <tr mat-row *matRowDef="let row; columns: columnKeys.concat(customActions.length > 0 ? ['actions'] : [])"></tr>
    </table>
    <ng-container *ngIf="dataSource?.data?.length === 0">
        <div class="text-center mt-3">{{ 'NO_DATA_FOUND' | translate }}</div>
    </ng-container>
    <!-- <mat-paginator [length]="totalPosts" [pageSize]="postsPerPage" (page)="onChangedPage($event)"></mat-paginator> -->
    <mat-paginator #paginator [length]="totalPosts" [pageSize]="postsPerPage" [pageSizeOptions]="pageSizeOptions"
        *ngIf="isPaginationShowed" (page)="onChangedPage($event)"></mat-paginator>
</div>