// export class Global {
//     //For Live Server
//    // public static BASE_API_PATH = "http://localhost:152489/api/";
//    public static ServerUrl: 'https://api.justbeflex.com/'; //client jay server IP test
//    public static BaseUrl: 'https://jbfapi.justbeflex.com/api/'; //client jay server IP test
//    public static FrontUrl: 'https://www.justbeflex.com/'; //client jay base url test;

//     //For Image Path
//     // public static BASE_IMAGES_PATH = "http://sahosoftweb.com/images/";
//     // public static BASE_USERS_IMAGES_PATH = "http://sahosoftweb.com/users/";

//     public static Models: {
//         USER: 'User',
//         JOB: 'Job',
//         COUNTRY: 'Country',
//         STATE: 'State',
//         CITY: 'City',
//         ADDRESS: 'Address',
//         CATEGORIES: 'Category',
//         SKILL: 'Skill',
//         EVENT: 'Event',
//         MEMBER: "Member",
//         EVENT_CATEGORY: 'EventCategory',
//         GROUP: 'Group',
//         COWORKING: 'Coworking',
//         SERVICE: 'Service',
//         DEGREE: 'Degree',
//         ROOM: 'Room',
//         SPAMJOB: 'SpamJobs',
//         BOOSTCREDIT: 'BoostCredits',
//         USERCREDITS: 'UserCredits',
//         SYSTEM_SETTING: 'SystemSettings',
//         ORDER: 'Order',
//         FACILITY: 'Facility',
//         SPACE_TYPE: 'SpaceType',
//         INVOICE: 'Invoice',
//         DIPLOMAINFO: 'DiplomaInfo',
//         GROUP_CATEGORY: 'GroupCategory',
//         SPACE_CATEGORY: 'SpaceCategory',
//         JOB_INDUSRTY: 'JobIndustry',
//         JOB_TYPE: 'JobType',
//         APPLIED_JOB: 'JobApplication',
//         JOB_LOCATION: 'JobLocation',
//         JOB_COMPENSATION: 'JobCompensation',
//         JOB_EXPERIENCE: 'JobExperience',
//         JOB_DESIGNATION: 'JobDesignation',
//         QUALIFICATION: 'Qualification',
//         SPAMRECORDS: 'SpamRecords',
//         FEED: 'Feed',
//         COMPANY_FACILITY: "Companyfacility",
//         USER_STATUS: "UserStatus",
//         COMPANY_STATUS: "CompanyStatus",
//         DIPARTMENT: "Dipartment",
//         OFFERS: "Offers",
//         USER_CATEGORIES: "UserCategories",
//         USER_PERSONALITY: "UserPersonalities",
//         PLAN_MASTER: 'Planmaster',
//         PRICE: 'Price',
//         PLAN: 'Plan',
//         COMPANY_VERIFICATION: 'CompanyVerification',
//         ROLL: 'Roll',
//         TIMEZONE: "Timezone",
//         CONTACTUS: 'Contactus',
//         PROFESSION: 'Profession',
//         SERVICE_CATEGORY: 'ServiceCategory',
//         ACCEPTOFFER: 'AcceptOffer',
//         NEWCOMPANY: "AddCompany",
//         USER_RELATIONSHIP: "UserRelationship",
//         VAT: "vat",
//         POPUPS: "Popups",
//         COMMUNITY_ROLL: "Community_Roll",
//         TERMS: "Terms",
//         PRIVACY_POLICY: "Privacy_Policy",
//         WELCOME: "Welcome",
//         FREEMIUM_LIMIT: "freemium_limit",
//         EVENT_SUBJECT: "Event_Subject",
//         FAQ: "FAQ",
//         SUBSCRIBER: "subscriber",
//         USER_LANGUAGE :'Language',
//         THEME : 'Theme',
//         THEME_TYPE : 'Themetype',
//         THEME_MODE : 'Thememode',
//         CATEGORY_TYPE : 'Categorytype',
//         CATEGORY_SKILL : 'Categoryskill',
//         LANGUAGE_SKILL : 'LanguageSkill',
//         LANGUAGE: "Language",
//         SKILLUSERCATEGORY: "SkillUserCategory",
//         SKILLUSERLANGUAGE: "SkillUserLanguage",
//         SKILLUSERTHEME: "SkillUserTheme",
//         TRANSCATION : 'Transcation',
//         QUOTEREQUEST : 'QuoteRequest',
//         ADDBOOKINGS: 'Bookings',
//         NOTIFICATION: 'Notification'
//      }

//      public static URLS: {
//         QUERY: 'query/',
//         CONDITIONS: 'execute/conditions/',
//         UPLOADED_PATH: 'http://localhost/tfc/server/',
//         UPLOAD_PATH: 'upload/',
//         SKILL_USER: 'users/get/skilluser/',
//         UPDATE_JOB: 'job/update_job',
//         CREATE_JOB: 'job/create_job',
//         COMPANY_CLOSE: 'company/company_close',
//         CREATE_SPACE: 'workspace/create_space',
//         CHANGE_PASSWORD: 'users/updatepassword',
//     }
// }

export class Global {
  //For Live Server
  // public static ServerUrl: 'https://jbfapiv3.justbeflex.com/'; //Live URL
  //public static ServerUrl = 'https://jbfapi.justbeflex.com/'; // Development URL
  // public static BASE_API_PATH = 'https://jbfapiv3.justbeflex.com/api/'; //Live URL
  //public static BASE_API_PATH = 'https://jbf-be.sctestinglab.com/'; // Development URL
  // public static ServerUrl: 'https://jbfapi.justbeflex.com/';
  // public static BASE_API_PATH = "https://jbf-be.sctestinglab.com/api/";

  //For Image Path
  // public static BASE_IMAGES_PATH = "http://sahosoftweb.com/images/";
  // public static BASE_USERS_IMAGES_PATH = "http://sahosoftweb.com/users/";

  //Pre release URLs
  // public static ServerUrl = 'https://jbfapiv3.sctestingsite.com/';
  // public static BASE_API_PATH = 'https://jbfapiv3.sctestingsite.com/api/';
  //Pre release URLs

  //Pre release URLs Client
  // public static ServerUrl = 'https://jbfapipr.justbeflex.com/';
  // public static BASE_API_PATH = 'https://jbfapipr.justbeflex.com/api/';
  //Pre release URLs Client

  //Production Client
  public static ServerUrl = 'https://api.justbeflex.com/';
  public static BASE_API_PATH = 'https://api.justbeflex.com/api/';
  //Production Client
}
