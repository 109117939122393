<div class="page-wrapper">
    <app-header></app-header>
    <div class="page-body-wrapper">
        <div class="page-sidebar" [class.open]="_navService.collaspeSidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="page-body">
            <app-breadcrumb></app-breadcrumb>
            <router-outlet></router-outlet>
        </div>
        <footer class="footer">
            <app-footer></app-footer>
        </footer>
    </div>
</div>