import { environment } from './environments/environment';

export class config {
  // static ADD_SPACE_CATEGORY(ADD_SPACE_CATEGORY: any): string {
  //   throw new Error('Method not implemented.');
  // }
  // DEFAULTS
  static IS_PRODUCTION = environment.production;
  static REQUEST_POST = 'post';

  static API_BASE_URL = environment.API_BASE_URL;
  static API_ENDPONT_LOGIN_USER = environment.LOGIN_USER;
  static API_ENDPONT_USERS_LIST = environment.USERS_LIST;
  static API_ENDPONT_WELLCOME_LIST = environment.API_ENDPONT_WELLCOME_LIST;
  static API_ENDPOINT_CONTACTUS = environment.API_ENDPOINT_CONTACTUS;
  static API_ENDPONT_CONTACTUS_UPDATE =
    environment.API_ENDPONT_CONTACTUS_UPDATE;
  static API_ENDPOINT_WELLCOME_POPUP = environment.API_ENDPOINT_WELLCOME_POPUP;
  static API_ENDPOINT_WELLCOME_IMAGE = environment.API_ENDPOINT_WELLCOME_IMAGE;
  static API_ENDPOINT_FEED_LIST = environment.API_ENDPOINT_FEED_LIST;
  static API_ENDPOINT_FEED_LIST_ADD = environment.API_ENDPOINT_FEED_LIST_ADD;
  static API_ENDPOINT_FEED_LIST_IMAGE =
    environment.API_ENDPOINT_FEED_LIST_IMAGE;
  static API_ENDPOINT_SPACE_LIST = environment.API_ENDPOINT_SPACE_LIST;
  static API_ENDPOINT_FEED_LIST_ACTIVE =
    environment.API_ENDPOINT_FEED_LIST_ACTIVE;
  static API_ENDPOINT_FEED_LIST_DELETE =
    environment.API_ENDPOINT_FEED_LIST_DELETE;
  static API_ENDPONT_OFFER_LIST = environment.OFFER_LIST;
  static API_ENDPONT_COMMEN_NAME_LIST = environment.COMMEN_NAME_LIST;
  static API_ENDPONT_BASIC_USER_LIST = environment.BASIC_USER_LIST;
  static API_ENDPONT_ALL_COMPANY = environment.ALL_COMPANY;
  static API_ENDPONT_ADD_OFFER = environment.ADD_OFFER;
  static API_ENDPONT_DELETE_OFFER = environment.DELETE_OFFER;
  static API_ENDPONT_ACTIVE_DEACTIVE_OFFER = environment.ACTIVE_DEACTIVE_OFFER;
  static API_ENDPONT_NOTIFY_OFFER = environment.NOTIFY_OFFER;
  static API_ENDPOINT_FEED_LIST_SEND = environment.API_ENDPOINT_FEED_LIST_SEND;
  static API_ENDPONT_PLAN_LIST = environment.PLAN_LIST;
  static API_ENDPOINT_GET_IDWISE_PLANDETAILS =
    environment.API_ENDPOINT_GET_IDWISE_PLANDETAILS;
  static API_ENDPOINT_UPDATE_PLANDETAILS =
    environment.API_ENDPOINT_UPDATE_PLANDETAILS;
  static API_ENDPONT_MEMBER_TYPE_LIST =
    environment.API_ENDPONT_MEMBER_TYPE_LIST;
  static API_ENDPONT_DELETE_MEMBER_TYPE =
    environment.API_ENDPONT_DELETE_MEMBER_TYPE;
  static API_ENDPONT_ADD_MEMBER_TYPE = environment.API_ENDPONT_ADD_MEMBER_TYPE;
  static API_ENDPONT_ADD_MASTER_ROLES =
    environment.API_ENDPONT_ADD_MASTER_ROLES;
  static API_ENDPOINT_UPDATE_MEMBER_TYPE =
    environment.API_ENDPOINT_UPDATE_MEMBER_TYPE;
  static API_ENDPOINT_UPDATE_MASTER_ROLES =
    environment.API_ENDPOINT_UPDATE_MASTER_ROLES;
  static API_ENDPOINT_GET_IDWISE_MEMBERTYPE_DETAILS =
    environment.API_ENDPOINT_GET_IDWISE_MEMBERTYPE_DETAILS;
  static API_ENDPOINT_GET_IDWISE_MASTER_ROLES_DETAILS =
    environment.API_ENDPOINT_GET_IDWISE_MASTER_ROLES_DETAILS;
  static API_ENDPOINT_ADD_FREEMINUMLIMIT =
    environment.API_ENDPOINT_ADD_FREEMINUMLIMIT;
  static API_ENDPOINT_ADD_VAT = environment.API_ENDPOINT_ADD_VAT;
  static API_ENDPOINT_GET_FREEMINUMLIMIT =
    environment.API_ENDPOINT_GET_FREEMINUMLIMIT;
  static API_ENDPOINT_DELETE_MASTER_ROLE =
    environment.API_ENDPOINT_DELETE_MASTER_ROLE;

  static API_ENDPONT_DELETE_COMPANY_ROLES = environment.DELETE_COMPANY_ROLES;
  static API_ENDPONT_DELETE_COMMUNITY_ROLES =
    environment.DELETE_COMMUNITY_ROLES;
  static API_ENDPOINT_COMPANY_ROLES = environment.COMPANY_ROLES;
  static API_ENDPOINT_GET_SHARED_JOBS = environment.GET_SHARED_JOBS;
  static API_ENDPOINT_GET_EXPIRED_JOBS = environment.GET_EXPIRED_JOBS;
  static API_ENDPOINT_GET_DELETED_JOBS = environment.GET_DELETED_JOBS;
  static API_ENDPOINT_GET_SPAM_JOBS = environment.GET_SPAM_JOBS;
  static API_ENDPOINT_UPDATE_SPAM_JOBS = environment.UPDATE_SPAM_JOBS;
  static API_ENDPOINT_GET_ALL_JOB_APPLICATIONS =
    environment.GET_ALL_JOB_APPLICATIONS;
  static API_ENDPOINT_DELETE_JOB_APPLICATION =
    environment.DELETE_JOB_APPLICATION;
  static API_ENDPOINT_DELETE_SPAM_JOB = environment.DELETE_SPAM_JOB;
  static API_ENDPOINT_REACTIVE_DELETE_JOB = environment.REACTIVE_DELETE_JOB;
  static API_ENDPOINT_REACTIVE_DELETE_JOB_APPLICATION =
    environment.REACTIVE_DELETE_JOB_APPLICATION;
  static API_ENDPOINT_GET_DELETED_APPLICATIONS =
    environment.GET_DELETED_APPLICATIONS;
  static API_ENDPOINT_COMMUNITY_ROLES = environment.COMMUNITY_ROLES;
  static API_ENDPOINT_CREATE_COMPANY_ROLES = environment.CREATE_COMPANY_ROLES;
  static API_ENDPOINT_CREATE_COMMUNITY_ROLES =
    environment.CREATE_COMMUNITY_ROLES;
  static API_ENDPOINT_INVOICE_LIST = environment.INVOICE_LIST;
  static API_ENDPOINT_CREATE_FANDQ = environment.API_ENDPOINT_CREATE_FANDQ;
  static API_ENDPOINT_FANDQ_DELETE = environment.API_ENDPOINT_FANDQ_DELETE;
  static API_ENDPOINT_FANDQ_UPDATE = environment.API_ENDPOINT_FANDQ_UPDATE;
  static API_ENDPOINT_FANDQ_GET_LIST = environment.API_ENDPOINT_FANDQ_GET_LIST;
  static API_ENDPOINT_FANDQ_GET = environment.API_ENDPOINT_FANDQ_GET;
  static API_ENDPOINT_SUBSCRIBER_LIST =
    environment.API_ENDPOINT_SUBSCRIBER_LIST;
  static API_ENDPOINT_SUBSCRIBER_DELETE =
    environment.API_ENDPOINT_SUBSCRIBER_DELETE;
  static API_ENDPOINT_COMPANY_VERIFICATION_LIST =
    environment.API_ENDPOINT_COMPANY_VERIFICATION_LIST;
  static API_ENDPOINT_COMPANY_VERIFICATION_UPDATE =
    environment.API_ENDPOINT_COMPANY_VERIFICATION_UPDATE;
  static API_ENDPOINT_COMPANY_VERIFICATION_DETAILS =
    environment.API_ENDPOINT_COMPANY_VERIFICATION_DETAILS;
  static API_ENDPOINT_USER_UPDATE = environment.API_ENDPOINT_USER_UPDATE;
  static API_ENDPOINT_USER_REVERIFIED =
    environment.API_ENDPOINT_USER_REVERIFIED;
  static API_ENDPOINT_BUSSINESS_LIST = environment.API_ENDPOINT_BUSSINESS_LIST;
  static API_ENDPONT_GET_COMMUNITIES = environment.GET_COMMUNITIES;
  static API_ENDPONT_COMMUNITIES_UPDATE = environment.COMMUNITIES_UPDATE;
  static COMMON_NAME_LISTS = environment.COMMON_NAME_LISTS;
  static API_ENDPONT_COMPANY_DETAILS = environment.COMPANY_DETAILS;
  static API_ENDPONT_ALL_PREMIUM_MEMBER = environment.ALL_PREMIUM_MEMBER;
  static API_ENDPOINT_GET_CATEGORY_TYPE = environment.GET_CATEGORY_TYPE;
  static API_ENDPONT_DELETE_CATEGORY_TYPE = environment.DELETE_CATEGORY_TYPE;
  static API_ENDPOINT_GET_COMMON_NAME_LIST = environment.GET_COMMON_NAME_LIST;
  static API_ENDPOINT_ADD_CATEGORY_TYPE = environment.ADD_CATEGORY_TYPE;
  static API_ENDPOINT_GET_IDWISE_CATEGORYTYPE_DETAILS =
    environment.GET_IDWISE_CATEGORYTYPE_DETAILS;
  static API_ENDPOINT_UPDATE_CATEGORY_TYPE = environment.UPDATE_CATEGORY_TYPE;
  static API_ENDPOINT_GET_CATEGORY_SKILLS = environment.GET_CATEGORY_SKILLS;
  static API_ENDPONT_DELETE_CATEGORY_SKILLS =
    environment.DELETE_CATEGORY_SKILLS;
  static API_ENDPOINT_ADD_CATEGORY_SKILLS = environment.ADD_CATEGORY_SKILLS;
  static API_ENDPOINT_UPDATE_CATEGORY_SKILLS =
    environment.UPDATE_CATEGORY_SKILLS;
  static API_ENDPOINT_GET_IDWISE_CATEGORY_SKILLS_DETAILS =
    environment.GET_IDWISE_CATEGORY_SKILLS_DETAILS;
  static API_ENDPONT_DELETE_LANGUAGE = environment.DELETE_LANGUAGE;
  static API_ENDPOINT_ADD_COMMAN_NAME = environment.ADD_COMMAN_NAME;
  static API_ENDPOINT_GET_IDWISE_COMMAN_NAME_DETAILS =
    environment.GET_IDWISE_COMMAN_NAME_DETAILS;
  static API_ENDPOINT_UPDATE_COMMAN_NAME = environment.UPDATE_COMMAN_NAME;
  static API_ENDPOINT_GET_LANGUAGE_SKILLS = environment.GET_LANGUAGE_SKILLS;
  static API_ENDPONT_DELETE_LANGUAGE_SKILLS =
    environment.DELETE_LANGUAGE_SKILLS;
  static API_ENDPOINT_ADD_LANGUAGE_SKILLS = environment.ADD_LANGUAGE_SKILLS;
  static API_ENDPOINT_GET_IDWISE_LANGUAGE_SKILLS_DETAILS =
    environment.GET_IDWISE_LANGUAGE_SKILLS_DETAILS;
  static API_ENDPOINT_UPDATE_LANGUAGE_SKILLS =
    environment.GET_IDWISE_UPDATE_LANGUAGE_SKILLS;
  static API_ENDPOINT_GET_THEME_TYPE = environment.GET_THEME_TYPE;
  static API_ENDPONT_DELETE_THEME_TYPE = environment.DELETE_THEME_TYPE;
  static API_ENDPOINT_ADD_THEME_TYPE = environment.ADD_THEME_TYPE;
  static API_ENDPOINT_GET_IDWISE_THEME_TYPE_DETAILS =
    environment.GET_IDWISE_THEME_TYPE_DETAILS;
  static API_ENDPOINT_UPDATE_THEME_TYPE = environment.UPDATE_THEME_TYPE;
  static API_ENDPOINT_GET_IDWISE_ROLES_DETAILS =
    environment.API_ENDPOINT_GET_IDWISE_ROLES_DETAILS;
  static API_ENDPOINT_UPDATE_ROLE = environment.API_ENDPOINT_UPDATE_ROLE;
  static API_ENDPOINT_GET_THEME_MODE = environment.GET_THEME_MODE;
  static API_ENDPONT_DELETE_THEME_MODE = environment.DELETE_THEME_MODE;
  static API_ENDPOINT_ADD_THEME_MODE = environment.ADD_THEME_MODE;
  static API_ENDPOINT_GET_IDWISE_THEME_MODE_DETAILS =
    environment.GET_IDWISE_THEME_MODE_DETAILS;
  static API_ENDPOINT_UPDATE_THEME_MODE = environment.UPDATE_THEME_MODE;
  static API_ENDPOINT_GET_USERS_DASHBOARD = environment.GET_USERS_DASHBOARD;
  static API_ENDPOINT_GET_SERVICES_PRODUCTS = environment.GET_SERVICES_PRODUCTS;
  static API_ENDPOINT_UPDATE_SERVICES = environment.UPDATE_SERVICES;
  static API_ENDPOINT_GET_SERVICE_CATEGORY = environment.GET_SERVICE_CATEGORY;
  static API_ENDPOINT_GET_COUNTRYLIST = environment.GET_COUNTRYLIST;
  static API_ENDPOINT_GET_STATELIST = environment.GET_STATELIST;
  static API_ENDPOINT_UPDATE_USER_PROFILE = environment.UPDATE_USER_PROFILE;
  // static API_ENDPOINT_GET_SPACE_OF_TYPE = environment.GET_SPACE_OF_TYPE;
  // static API_ENDPOINT_ADD_SPACECATEGORY = environment.ADD_SPACE_CATEGORY;
  // static API_ENDPOINT_GET_TYPE_OF_SPACECATEGORYLIST = environment.GET_SPACE_CATEGORYLIST
  // static API_ENDPOINT_GET_IDWISE_SPACECATEGORY_DETAILS = environment.GET_SPACE_CATEGORY_IDWISE_DETAILS;
  // static API_ENDPOINT_PUT_TYPE_OF_SPACECATEGORY = environment.UPDATE_SPACE_CATEGORY;
  // static API_ENDPOINT_PUT_TYPE_OF_SPACE = environment.UPDATE_SPACE_OF_TYPE;
  // static API_ENDPOINT_PUT_TYPE_OF_SPACE_FACILITY = environment.UPDATE_SPACE_FACILITY;
  // static API_ENDPOINT_GET_SPACE_FACILITY = environment.GET_SPACE_FACILITY;
  static API_ENDPOINT_GET_EVENTS_LIST = environment.GET_EVENTS_LIST;
  static API_ENDPOINT_EVENT_UPDATE = environment.EVENT_UPDATE;
  static API_ENDPOINT_SPACE_CATEGORY_TYPE = environment.SPACE_CATEGORY_TYPE;
  static API_ENDPOINT_GET_PRIVACY_POLICY_API = environment.PRIVACY_POLICY_API;
  static API_ENDPOINT_CREATE_POLICY_API = environment.CREATE_POLICY_API;
  static API_ENDPOINT_GET_POLICY_DETAIL = environment.GET_POLICY_DETAIL;
  static API_ENDPOINT_UPDATE_POLICY_DETAIL = environment.UPDATE_POLICY_DETAIL;
  static API_ENDPOINT_GET_JOIN_MEMBER_LIST = environment.GET_JOIN_MEMBER_LIST;
  static API_ENDPOINT_ADMIN_SPACE_LIST = environment.ADMIN_SPACE_LIST;
  static API_ENDPOINT_UPDATE_SPACE_LIST = environment.UPDATE_SPACE_LIST;
  static API_ENDPONT_STATISTICS_PLAN_DATA = environment.STATISTICS_PLAN_DATA;
  static API_ENDPONT_GET_EXPERIENCE_LIST = environment.GET_EXPERIENCE_LIST;
  static API_ENDPONT_UPDATE_EXPERIENCE = environment.UPDATE_EXPERIENCE;
  static API_ENDPONT_DELETE_EXPERIENCE = environment.DELETE_EXPERIENCE;
  static API_ENDPONT_GET_CATEGORY_WAY_LIST = environment.GET_CATEGORY_WAY_LIST;
  static API_ENDPONT_UPDATE_CATEGORY_WAY = environment.UPDATE_CATEGORY_WAY;
  static API_ENDPONT_DELETE_CATEGORY_WAY = environment.DELETE_CATEGORY_WAY;
  static API_ENDPOINT_GET_TERMS_API = environment.TERMS_API;
  static API_ENDPONT_GET_INTEREST_SKILL_LIST = environment.GET_INTEREST_SKILL_LIST;
  static API_ENDPONT_UPDATE_INTEREST_SKILL_LIST = environment.UPDATE_INTEREST_SKILL_LIST;
  static API_ENDPONT_DELETE_INTEREST_SKILL_LIST = environment.DELETE_INTEREST_SKILL_LIST;
  static API_ENDPONT_GET_INTEREST_SKILL_DETAILS = environment.GET_INTEREST_SKILL_DETAILS;
  static API_ENDPONT_ADD_INTEREST_SKILL = environment.ADD_INTEREST_SKILL;
  static API_ENDPONT_CHANGE_PASSWORD = environment.CHANGE_PASSWORD;
  static API_ENDPOINT_GET_SPONTANEOUS_JOB_APPLICATIONS = environment.GET_SPONTANEOUS_JOB_APPLICATIONS;
  static API_ENDPOINT_DELETE_SPONTANEOUS_APPLICATION = environment.DELETE_SPONTANEOUS_APPLICATION;
  static API_ENDPOINT_GET_DELETED_SPONTANEOUS_APPLICATIONS = environment.GET_DELETED_SPONTANEOUS_APPLICATIONS;
  static API_ENDPONT_GET_COMMON_CATEGORY_LIST = environment.GET_CONDITION_COMMONLIST;
}
