import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  auth_token: any;

  constructor(private _httpClient: HttpClient, private apiService: ApiService) {

    const token = localStorage.getItem('token');
    this.auth_token = token;
  }

  get(url: string): Observable<any> {

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.auth_token}`
    })

    return this._httpClient.get(url, {
      headers: httpHeaders
    });
  }

  post(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);

    return this._httpClient.post(url, body);
  }

  postImages(url: string, model: any): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('isImage', '')

    return this._httpClient.post(url, model, {
      headers: httpHeaders
    });
  }

  put(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);

    // let httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${this.auth_token}`
    // })

    // return this._httpClient.put(url , body, {
    //   headers: httpHeaders
    // });
    return this._httpClient.put(url, body);
  }

  delete(url: string, Id: number): Observable<any> {

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.auth_token}`
    })

    return this._httpClient.delete(url + Id, {
      headers: httpHeaders
    });
  }



}
