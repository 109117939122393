import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { CommonService } from '../../services/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-mat-spinner',
  templateUrl: './mat-spinner.component.html',
  styleUrls: ['./mat-spinner.component.scss']
})
export class MatSpinnerComponent implements OnInit {
  @Input() value = 100;
  @Input() diameter = 80;
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() strokeWidth = 10;
  @Input() isLoading = false;
  @Input() color = 'primary';
  // private destroy$: Subject<unknown> = new Subject();

  constructor(public commonService: CommonService) {
  }


  ngOnInit(): void {
  }
}
