import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/config';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyList } from 'src/app/interface/companyList.interface';
import { UserList } from 'src/app/interface/userList.interface';
import { CommonNameList } from 'src/app/interface/commonName.interface';
import { User } from 'src/app/interface/user.interface';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { OfferList } from 'src/app/interface/offerList.interface';
import { Pagination } from 'src/app/interface/pagination.interface';
import { CreateOffer } from 'src/app/interface/createoffer.interface';
import { PlanList } from 'src/app/interface/planList.interface';
import { UpdatePlan } from 'src/app/interface/updatePlanInterface';
import { MemberType } from 'src/app/interface/memberType.inteface';
import { FreeminumLimit } from 'src/app/interface/freeminumLimit.interface';
import { promise } from 'protractor';
import { CommonService } from './common.service';
// import { promises } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: string;
  public isAuthenticated = false;
  isUploadImage: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    public toastr: ToastrService,
    private translate: TranslateService,
    private commonService: CommonService
  ) { }

  // IMAGE_PATH = 'https://newjbfdev.s3.eu-west-3.amazonaws.com/'; // Development URL
  IMAGE_PATH = 'https://jbfprodimages.s3.eu-west-3.amazonaws.com/'; // live URL Pre Release

  getAPIURL(url: string) {
    return config.API_BASE_URL + url;
  }

  getWellComeList(page: any): Observable<any> {
    let data = { page: page };
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_WELLCOME_LIST),
      data
    );
  }

  getWellComePopUp(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_WELLCOME_POPUP),
      data
    );
  }

  getWellComeImage(id: any): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_WELLCOME_IMAGE) + '/' + id
    );
  }

  getContactUsList(data: any): Observable<any> {
    // let data = { page: page, status: status };
    return this.http.post(this.getAPIURL(config.API_ENDPOINT_CONTACTUS), data);
  }

  updateContactUsList(id: number, data: any): Observable<any> {
    return this.http.put(
      this.getAPIURL(config.API_ENDPONT_CONTACTUS_UPDATE) + '/' + id,
      data
    );
  }

  getFeedListList(): Observable<any> {
    let data = { page: 0 };
    return this.http.post(this.getAPIURL(config.API_ENDPOINT_FEED_LIST), data);
  }

  getProfileDetail(): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_USER_PROFILE)
    );
  }

  addFeedListList(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_FEED_LIST_ADD),
      data
    );
  }

  getFeedImage(id: any): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_FEED_LIST_IMAGE) + '/' + id
    );
  }

  getCountryList(): Observable<any> {
    return this.http.get(this.getAPIURL(config.API_ENDPOINT_GET_COUNTRYLIST));
  }

  getCountryStateList(data: any): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_STATELIST),
      data
    );
  }

  // Get local storage token
  getLocalStorage(key: any) {
    let value = localStorage.getItem(key);
    if (value) {
      return value;
    }
    return '';
  }
  // Set local storage data by key
  setLocalStorage(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getLoginUser(data: User, options?: { headers?: HttpHeaders }): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_LOGIN_USER),
      data,
      options
    );
    // try {
    //   let user;
    //   const userData = this.http.post(
    //     this.getAPIURL(config.API_ENDPONT_LOGIN_USER),
    //     data,
    //     options
    //   );
    //   user = await lastValueFrom(userData);
    //   if (user.is_error === true) {
    //     this.toastr.error(user.message, '', { timeOut: 3000 });
    //   } else {
    //     if (user) {
    //       const token = user.data.token;
    //       this.token = token;
    //       if (token) {
    //         this.isAuthenticated = true;
    //         localStorage.setItem('userDetails', this.token);
    //         this.router.navigate(['/dashboard']);
    //         return user;
    //       }
    //     }
    //   }
    // } catch (error) {
    //   this.toastr.error(error.error.message, '', { timeOut: 3000 });
    // }
  }

  logout() {
    localStorage.clear();
    this.isAuthenticated = false;
    this.router.navigate(['auth/login']);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      this.router.navigate(['auth/login']);
      return;
    }
    if (authInformation) {
      this.isAuthenticated = true;
      this.token = authInformation.token;
    }
  }

  private getAuthData() {
    const data = localStorage.getItem('userDetails');
    const token = data;
    if (!token) {
      this.router.navigate(['auth/login']);
      return;
    }
    return {
      token: token,
    };
  }

  fileValidation(file: any, fileType: string) {
    let image = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    let video = ['video/mp4'];
    let pdf = ['application/pdf'];
    switch (fileType) {
      case 'image': {
        if (!image.includes(file.type)) {
          return false;
        } else {
          return true;
        }
      }
      case 'video': {
        if (!video.includes(file.type)) {
          return false;
        } else {
          return true;
        }
      }
      case 'portfolio': {
        if (video.includes(file.type) || image.includes(file.type)) {
          return true;
        } else {
          return false;
        }
      }
      case 'pdf': {
        if (!pdf.includes(file.type)) {
          return false;
        } else {
          return true;
        }
      }
      default: {
      }
    }
  }

  getSwalErrorPopUp(message: string) {
    Swal.fire({
      icon: 'error',
      title: this.translate.instant('LBL_OOPS'),
      text: this.translate.instant(message),
    });
    return;
  }

  swalConfirmationPopUp(title: string, message: string) {
    const text =
      this.translate.instant(message) +
      '\n' +
      this.translate.instant('LBL_DELELTE_COMMENT_S');
    Swal.fire({
      title: this.translate.instant(title),
      html: '<pre>' + text + '</pre>',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('LBL_DELETE_YES'),
      cancelButtonText: this.translate.instant('LBL_DELETE_NO'),
    }).then((result) => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  // getCountriesList(){

  // }

  getUsersList(data: any): Observable<any> {
    return this.http.post(this.getAPIURL(config.API_ENDPONT_USERS_LIST), data);
  }

  getPrivacyPolicyData(data: any) {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_PRIVACY_POLICY_API),
      data
    );
  }

  postPrivacyPolicyData(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_CREATE_POLICY_API),
      data
    );
    // return await lastValueFrom(result);
  }

  getTermsData(data: any) {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_TERMS_API),
      data
    );
  }

  updatePolicyDetail(id: number, data: any): Observable<any> {
    return this.http.patch(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_POLICY_DETAIL) + '/' + id,
      data
    );
  }

  getOfferLists(data: Pagination): Observable<any> {
    return this.http.post(this.getAPIURL(config.API_ENDPONT_OFFER_LIST), data);
  }

  getCommunities(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_GET_COMMUNITIES),
      data
    );
  }

  updateCommunityStatus(id: number, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_COMMUNITIES_UPDATE) + '/' + id,
      data
    );
  }

  getSharedJob(data: any): Observable<any> {
    // let value = { type: 'Shared', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SHARED_JOBS),
      data
    );
  }

  getExpiredJobs(data: Pagination): Observable<any> {
    // let value = { type: 'Expired', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_EXPIRED_JOBS),
      data
    );
  }

  getDeletedJobs(data: Pagination): Observable<any> {
    // let value = { type: 'Deleted', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_DELETED_JOBS),
      data
    );
  }

  getSpamJob(data: any): Observable<any> {
    // let value = { type: 'Spam', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SPAM_JOBS),
      data
    );
  }

  getSharedServicesProducts(data: any): Observable<any> {
    let value = { type: 'Shared', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SERVICES_PRODUCTS),
      value
    );
  }

  updateSpamJob(id: number, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_SPAM_JOBS) + '/' + id,
      data
    );
  }

  getAllJobApplication(data: Pagination): Observable<any> {
    // let value = { type: 'All', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_ALL_JOB_APPLICATIONS),
      data
    );
  }

  getSpontaneousJobApplication(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SPONTANEOUS_JOB_APPLICATIONS),
      data
    );
  }

  deleteJobApplication(id: number): Observable<any> {
    let value = { isDeleted: 'true' };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_DELETE_JOB_APPLICATION) + '/' + id,
      value
    );
  }

  updateSpontaneousJobApplication(id: string, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_DELETE_SPONTANEOUS_APPLICATION) + '/' + id,
      data
    );
  }


  deleteSpamJobs(id: number): Observable<any> {
    let value = { isDeleted: 'true', isLocked: 'false' };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_DELETE_SPAM_JOB) + '/' + id,
      value
    );
  }

  reactiveDeleteJobs(id: number): Observable<any> {
    let value = { isDeleted: 'false', isLocked: "false" };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_REACTIVE_DELETE_JOB) + '/' + id,
      value
    );
  }

  reactiveDeletedApplication(id: number): Observable<any> {
    let value = { isDeleted: 'false' };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_REACTIVE_DELETE_JOB_APPLICATION) +
      '/' +
      id,
      value
    );
  }

  getDeletedApplications(data: Pagination): Observable<any> {
    // let value = { type: 'Deleted', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_DELETED_APPLICATIONS),
      data
    );
  }

  getDeletedSpontaneousApplications(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_DELETED_SPONTANEOUS_APPLICATIONS),
      data
    );
  }

  getCompanyRoles(data: Pagination): Observable<any> {
    let value = { roleType: 'COMPANY', data };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_COMPANY_ROLES),
      value
    );
  }

  getCommunityRoles(data: Pagination): Observable<any> {
    let value = { roleType: 'COMMUNITY', data, where: { is_deleted: false } };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_COMMUNITY_ROLES),
      value
    );
  }

  getInvoiceList(data: Pagination): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_INVOICE_LIST),
      data
    );
  }

  async getPlanList(): Promise<any> {
    let data = { page: 0 };
    let allUser = this.http.post(
      this.getAPIURL(config.API_ENDPONT_PLAN_LIST),
      data
    );
    return await lastValueFrom(allUser);
  }

  async getCommonNameList(value: string): Promise<any> {
    let data: CommonNameList = {
      type: value,
      name: undefined
    };
    let allNameLists = this.http.post(
      this.getAPIURL(config.API_ENDPONT_COMMEN_NAME_LIST),
      data
    );
    return await lastValueFrom(allNameLists);
  }

  feedListActive(id: number, data: any): Observable<any> {
    return this.http.put(
      this.getAPIURL(config.API_ENDPOINT_FEED_LIST_ACTIVE) + '/' + id,
      data
    );
  }

  feedListDelete(id: number): Observable<any> {
    return this.http.delete(
      this.getAPIURL(config.API_ENDPOINT_FEED_LIST_DELETE) + '/' + id
    );
  }

  async getAllCompanyDetails(): Promise<any> {
    let allCompanyDetails = this.http.get(
      this.getAPIURL(config.API_ENDPONT_COMPANY_DETAILS)
    );
    return await lastValueFrom(allCompanyDetails);
  }

  async getAllPremiumMember(): Promise<any> {
    let data: { list: true };
    let allPremiumMemberList = this.http.post(
      this.getAPIURL(config.API_ENDPONT_ALL_PREMIUM_MEMBER),
      data
    );
    return await lastValueFrom(allPremiumMemberList);
  }

  async addOffer(data: any, file): Promise<any> {
    let formData = new FormData();
    const offerSentenceObj = { en: data.offerSentenceEn, fr: data.offerSentenceFr }
    const offerSentenceObjString = JSON.stringify(offerSentenceObj);
    const titleObject = { en: data.title, fr: data.french_title };
    const descriptionObject = { en: data.description, fr: data.fr_description };
    const titleObjectString = JSON.stringify(titleObject);
    const descriptionObjectString = JSON.stringify(descriptionObject);
    const selectionObjectString = JSON.stringify(data.selectedOptions);
    const locationData = { coordinates: [41.40338, 2.17403] };
    const locationDataString = JSON.stringify(locationData);
    formData.append('title', titleObjectString);
    formData.append('offerSentence', offerSentenceObjString);
    formData.append('target', data.target);
    formData.append('description', descriptionObjectString);
    formData.append('plan', data.offers);
    formData.append('targetType', data.targetType);
    formData.append('targetStatus', selectionObjectString);
    formData.append('location', locationDataString);
    formData.append('quantity', data.quantity);
    formData.append('price', data.price);
    formData.append('discount', data.discount);
    formData.append('discountAmount', data.discountAmount);
    formData.append('totalAmount', data.finalAmount);
    formData.append('offerDate', data.offerDate);
    formData.append('offerEndDate', data.offerEndDate);
    formData.append('currencySymbol', data.currencySymbol);
    formData.append('image', file);
    let offerData = this.http.post(
      this.getAPIURL(config.API_ENDPONT_ADD_OFFER),
      formData
    );
    return await lastValueFrom(offerData);
  }

  async addCompanyRoles(data): Promise<any> {
    let offerData = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_CREATE_COMPANY_ROLES),
      data
    );
    return await lastValueFrom(offerData);
  }

  async addCommunityRoles(data): Promise<any> {
    let offerData = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_CREATE_COMMUNITY_ROLES),
      data
    );
    return await lastValueFrom(offerData);
  }

  async deleteOffer(id: number): Promise<any> {
    let user = this.http.post(
      this.getAPIURL(config.API_ENDPONT_DELETE_OFFER) + '/' + id,
      null
    );
    return await lastValueFrom(user);
  }

  async deleteCompanyRoles(id: number): Promise<any> {
    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_COMPANY_ROLES) + '/' + id
    );
    return await lastValueFrom(user);
  }

  async deleteCommunityRoles(id: number): Promise<any> {
    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_COMMUNITY_ROLES) + '/' + id
    );
    return await lastValueFrom(user);
  }

  async activeDeactiveOffer(id: string): Promise<any> {
    let data = this.http.put(
      this.getAPIURL(config.API_ENDPONT_ACTIVE_DEACTIVE_OFFER) + '/' + id,
      null
    );
    return await lastValueFrom(data);
  }

  async notifyOffer(data: OfferList, id: string): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPONT_NOTIFY_OFFER) + '/' + id,
      data
    );
    return await lastValueFrom(result);
  }

  feedSentList(id: number, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_FEED_LIST_SEND) + '/' + id,
      data
    );
  }

  async getSpaceList(sapce: {
    list: boolean;
    page: number;
    searchLocation: string;
    company: string;
    people: number;
    spaceType: any[];
    spaceCategory: any[];
    facility: any[];
    sort: { createDate: number };
  }): Promise<any> {
    const allNameLists = this.http.post<any>(
      this.getAPIURL(config.API_ENDPOINT_SPACE_LIST),
      sapce
    );
    return await lastValueFrom(allNameLists);
  }

  async userIdWisePlanDetails(id: string): Promise<any> {
    const allPlanDetails = this.http.get<any>(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_PLANDETAILS) + id
    );
    return await lastValueFrom(allPlanDetails);
  }

  async updatePlanDetails(id: string, data: UpdatePlan): Promise<any> {
    const formData = {
      name: { en: data.enName, fr: data.frName },
      description: { en: data.enDescription, fr: data.frDescription },
      price: data.price,
      jobCount: data.jobs,
      spaceCount: data.spaces,
      communityCount: data.communities,
      eventCount: data.events,
      feedCount: data.services,
      companyCount: data.companies,
      bookCount: data.books,
      status: data.selectStatus,
      duration: data.duration,
      membershipType: data.membershipType,
      number: data.no,
    };
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_PLANDETAILS) + '/' + id,
      formData
    );
    return await lastValueFrom(result);
  }

  async getPlanDetails(data: any): Promise<any> {
    const allPlanDetails = this.http.post(
      this.getAPIURL(config.API_ENDPONT_PLAN_LIST),
      data
    );
    return await lastValueFrom(allPlanDetails);
  }

  async createMemberType(formData: MemberType): Promise<any> {
    let offerData = this.http.post(
      this.getAPIURL(config.API_ENDPONT_ADD_MEMBER_TYPE),
      formData
    );
    return await lastValueFrom(offerData);
  }

  async createMasterRoles(formData): Promise<any> {
    let offerData = this.http.post(
      this.getAPIURL(config.API_ENDPONT_ADD_MASTER_ROLES),
      formData
    );
    return await lastValueFrom(offerData);
  }

  async getMemberTypePlanDetails(data: Pagination): Promise<any> {
    const allPlanDetails = this.http.post(
      this.getAPIURL(config.API_ENDPONT_MEMBER_TYPE_LIST),
      data
    );
    return await lastValueFrom(allPlanDetails);
  }

  async deleteMemberType(id: number): Promise<any> {
    console.log('api/commonName/commonNameDelete', id);

    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_MEMBER_TYPE) + '/' + id
    );
    return await lastValueFrom(user);
  }

  async userIdWiseMemberTypeDetails(id: string): Promise<any> {
    const allMemberType = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_MEMBERTYPE_DETAILS) + id
    );
    return await lastValueFrom(allMemberType);
  }

  async userIdWiseMasterRolesDetails(id: string): Promise<any> {
    const allMasterRoles = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_MASTER_ROLES_DETAILS) +
      '/' +
      id,
      {}
    );
    return await lastValueFrom(allMasterRoles);
  }

  async userIdWiseRoleDetails(id: string): Promise<any> {
    const allCommunityRoles = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_ROLES_DETAILS) + '/' + id,
      {}
    );
    return await lastValueFrom(allCommunityRoles);
  }

  async updateMemberType(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_MEMBER_TYPE),
      data
    );
    return await lastValueFrom(result);
  }

  updateRole(id: string, data): Observable<any> {
    return this.http.patch(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_ROLE) + '/' + id,
      data
    );
    // return await lastValueFrom(result);
  }

  async updateMasterRoles(id: string, data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_MASTER_ROLES) + '/' + id,
      data
    );
    return await lastValueFrom(result);
  }

  updateFreeminumLimit(data: FreeminumLimit) {
    console.log('data', data);
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_FREEMINUMLIMIT),
      data
    );
  }

  updateVatLimit(data) {
    console.log('data', data);
    return this.http.post(this.getAPIURL(config.API_ENDPOINT_ADD_VAT), data);
  }

  async getFreeminumVatLimit(): Promise<any> {
    const getFreeminumVat = this.http.get<any>(
      this.getAPIURL(config.API_ENDPOINT_GET_FREEMINUMLIMIT)
    );
    return await lastValueFrom(getFreeminumVat);
  }

  deleteMasterRole(id, data) {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_DELETE_MASTER_ROLE) + '/' + id,
      data
    );
  }

  fAndQSubmitForm(data: {}): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_CREATE_FANDQ),
      data
    );
  }

  fAndQDelete(id: number): Observable<any> {
    return this.http.delete(
      this.getAPIURL(config.API_ENDPOINT_FANDQ_DELETE) + '/' + id
    );
  }

  fAndQUpdate(id: number, data: {}): Observable<any> {
    return this.http.patch(
      this.getAPIURL(config.API_ENDPOINT_FANDQ_UPDATE) + '/' + id,
      data
    );
  }

  fAndQGetList(data: { page: number; searchName: string }): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_FANDQ_GET_LIST),
      data
    );
  }

  fAndQGetData(id: string): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_FANDQ_GET + '/' + id)
    );
  }

  subscribeList(data: {}): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_SUBSCRIBER_LIST),
      data
    );
  }

  subscribeDelete(id: number): Observable<any> {
    return this.http.delete(
      this.getAPIURL(config.API_ENDPOINT_SUBSCRIBER_DELETE) + '/' + id
    );
  }

  getCompanyVerificationList(data: any): Observable<any> {
    // let data = { type: status };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_COMPANY_VERIFICATION_LIST),
      data
    );
  }

  updateCompanyVerificationList(id: number, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_COMPANY_VERIFICATION_UPDATE) +
      '/' +
      id,
      data
    );
  }

  companyDetails(id: string, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_COMPANY_VERIFICATION_DETAILS) +
      '/' +
      id,
      data
    );
  }

  userUpdate(id: number, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_USER_UPDATE) + '/' + id,
      data
    );
  }

  userReVerify(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_USER_REVERIFIED),
      data
    );
  }

  getBussinessAccountList(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_BUSSINESS_LIST),
      data
    );
  }

  getHeader() {
    this.token = this.commonService.getLocalStorage('userDetails');
    let header = new HttpHeaders();

    if (!this.isUploadImage) {
      header = header.set('Content-Type', 'application/json');
    }
    header = header.set('Authorization', `${this.token}`);
    this.isUploadImage = false;
    return { headers: header };
  }

  getCommonList(data: any): Observable<any> {
    return this.http.post<any>(
      this.getAPIURL(config.COMMON_NAME_LISTS),
      data,
      this.getHeader()
    );
  }

  getCommonCategoryList(data: any): Observable<any> {
    return this.http.post<any>(
      this.getAPIURL(config.API_ENDPONT_GET_COMMON_CATEGORY_LIST),
      data
    );
  }

  getCategoryType(data: Pagination): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_CATEGORY_TYPE),
      data
    );
  }

  async deleteCategoryTypes(id: number): Promise<any> {
    let categoryType = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_CATEGORY_TYPE) + '/' + id
    );
    return await lastValueFrom(categoryType);
  }

  commanNameList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_COMMON_NAME_LIST),
      data
    );
  }

  async addCategoryType(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_CATEGORY_TYPE),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseCategoryTypeDetails(id: string): Promise<any> {
    const allCategoryType = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_CATEGORYTYPE_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allCategoryType);
  }

  updateCategoryType(data): Observable<any> {
    return this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_CATEGORY_TYPE),
      data
    );
    // return await lastValueFrom(result);
  }

  getCategorySkills(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_CATEGORY_SKILLS),
      data
    );
  }

  async deleteCategorySkills(id: number): Promise<any> {
    let categoryType = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_CATEGORY_SKILLS) + '/' + id
    );
    return await lastValueFrom(categoryType);
  }

  async addCategorySkills(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_CATEGORY_SKILLS),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseCategorySkillsDetails(id: string): Promise<any> {
    const allCategoryType = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_CATEGORY_SKILLS_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allCategoryType);
  }

  async updateCategorySkills(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_CATEGORY_SKILLS),
      data
    );
    return await lastValueFrom(result);
  }

  getCategoryTypeList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_CATEGORY_TYPE),
      data
    );
  }

  async deleteCommanName(id: number): Promise<any> {
    let language = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_LANGUAGE) + '/' + id
    );
    return await lastValueFrom(language);
  }

  async addCommanName(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_COMMAN_NAME),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseCommanNameDetails(id: string): Promise<any> {
    const allCommanName = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_COMMAN_NAME_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allCommanName);
  }

  async updateCommanName(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_COMMAN_NAME),
      data
    );
    return await lastValueFrom(result);
  }

  getLanguageSkillList(data: Pagination): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_LANGUAGE_SKILLS),
      data
    );
  }

  async deleteLanguageSkill(id: number): Promise<any> {
    let languageSkill = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_LANGUAGE_SKILLS) + '/' + id
    );
    return await lastValueFrom(languageSkill);
  }

  async addLanguageSkill(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_LANGUAGE_SKILLS),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseLanguageSkillsDetails(id: string): Promise<any> {
    const allLanguage = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_LANGUAGE_SKILLS_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allLanguage);
  }

  async updateLanguageSkills(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_LANGUAGE_SKILLS),
      data
    );
    return await lastValueFrom(result);
  }

  getThemeTypeList(data: Pagination): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_THEME_TYPE),
      data
    );
  }

  async deleteThemeType(id: number): Promise<any> {
    let themeType = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_THEME_TYPE) + '/' + id
    );
    return await lastValueFrom(themeType);
  }

  async addThemeType(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_THEME_TYPE),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseThemeTypeDetails(id: string): Promise<any> {
    const allThemeType = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_THEME_TYPE_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allThemeType);
  }

  async updateThemeType(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_THEME_TYPE),
      data
    );
    return await lastValueFrom(result);
  }

  getThemeModeList(data: Pagination): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_THEME_MODE),
      data
    );
  }

  async deleteThemeMode(id: number): Promise<any> {
    let themeMode = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_THEME_MODE) + '/' + id
    );
    return await lastValueFrom(themeMode);
  }

  getThemeTypeLists(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_THEME_TYPE),
      data
    );
  }

  async addThemeMode(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADD_THEME_MODE),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseThemeModeDetails(id: string): Promise<any> {
    const allThemeMode = this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_THEME_MODE_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allThemeMode);
  }

  async updateThemeMode(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_THEME_MODE),
      data
    );
    return await lastValueFrom(result);
  }

  getUsersDashboard(): Observable<any> {
    return this.http.get(
      this.getAPIURL(config.API_ENDPOINT_GET_USERS_DASHBOARD)
    );
  }

  // getSpacelist(data: any) {
  //   return this.http.post(this.getAPIURL(config.API_ENDPOINT_GET_SPACE_OF_TYPE), data);
  //   // return await lastValueFrom(result)
  // }

  // async updatetypeOfSpace(data) {
  //   let result = this.http.put(this.getAPIURL(config.API_ENDPOINT_PUT_TYPE_OF_SPACE), data);
  //   return await lastValueFrom(result)
  // }

  // async AddSpaceCategory(data): Promise<any> {
  //   let result = this.http.post(this.getAPIURL(config.
  //     API_ENDPOINT_ADD_SPACECATEGORY), data);
  //   return await lastValueFrom(result)
  // }

  // getSpaceCategory(data: any) {
  //   return this.http.post(this.getAPIURL(config.API_ENDPOINT_GET_TYPE_OF_SPACECATEGORYLIST), data);
  // }

  // async userIdWiseSpaceCategoryDetails(id: string): Promise<any> {
  //   const allCategoryType = this.http.get(this.getAPIURL(config.API_ENDPOINT_GET_IDWISE_SPACECATEGORY_DETAILS) + '/' + id);
  //   return await lastValueFrom(allCategoryType);
  // }

  // async updateSpaceCategory(data) {
  //   let result = this.http.put(this.getAPIURL(config.
  //     API_ENDPOINT_PUT_TYPE_OF_SPACECATEGORY), data);
  //   return await lastValueFrom(result);
  // }

  // async AddSpaceFacility(data): Promise<any> {
  //   let result = this.http.post(this.getAPIURL(config.
  //     API_ENDPOINT_ADD_SPACECATEGORY), data);
  //   return await lastValueFrom(result)
  // }

  // getSpaceFacility(data: any) {
  //   return this.http.post(this.getAPIURL(config.API_ENDPOINT_GET_SPACE_FACILITY), data);
  // }

  // async updateSpaceFacility(data) {
  //   let result = this.http.patch(this.getAPIURL(config.
  //     API_ENDPOINT_PUT_TYPE_OF_SPACECATEGORY), data);
  //   return await lastValueFrom(result);
  // }
  getEventsList(data: any): Observable<any> {
    // let data = { type: status };
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_EVENTS_LIST),
      data
    );
  }

  updateEventList(id: string, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_EVENT_UPDATE) + '/' + id,
      data
    );
  }

  getServicesList(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SERVICES_PRODUCTS),
      data
    );
  }

  updateServiceList(id: string, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_SERVICES) + '/' + id,
      data
    );
  }

  getServiceCategoryList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_SERVICE_CATEGORY),
      data
    );
  }

  spaceCategoryType(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_SPACE_CATEGORY_TYPE),
      data
    );
  }

  getJoinMemberList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_GET_JOIN_MEMBER_LIST),
      data
    );
  }

  getAdminSpaceList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_ADMIN_SPACE_LIST),
      data
    );
  }

  updateSpaceList(id: string, data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPOINT_UPDATE_SPACE_LIST) + '/' + id,
      data
    );
  }

  getStatisticsPlan(data: any): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_STATISTICS_PLAN_DATA),
      data
    );
  }

  experienceList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_GET_EXPERIENCE_LIST),
      data
    );
  }

  async createExperience(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPONT_UPDATE_EXPERIENCE),
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseExperienceDetails(id: string): Promise<any> {
    const allCategoryType = this.http.get(
      this.getAPIURL(config.API_ENDPONT_UPDATE_EXPERIENCE) +
      '/' +
      id
    );
    return await lastValueFrom(allCategoryType);
  }

  async updateExperienceDetail(id: string, data: any): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPONT_UPDATE_EXPERIENCE) + '/' + id,
      data
    );
    return await lastValueFrom(result);
  }

  async deleteExperience(id: string): Promise<any> {
    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_EXPERIENCE) + '/' + id
    );
    return await lastValueFrom(user);
  }

  categoryWayList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_GET_CATEGORY_WAY_LIST),
      data
    );
  }


  async createCategoryWay(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPONT_UPDATE_CATEGORY_WAY),
      data
    );
    return await lastValueFrom(result);
  }

  async updateCategoryWayDetail(id: string, data: any): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPONT_UPDATE_CATEGORY_WAY) + '/' + id,
      data
    );
    return await lastValueFrom(result);
  }

  async userIdWiseCategoryWayList(id: string): Promise<any> {
    const allCategoryType = this.http.get(
      this.getAPIURL(config.API_ENDPONT_UPDATE_CATEGORY_WAY) +
      '/' +
      id
    );
    return await lastValueFrom(allCategoryType);
  }

  async deleteCategoryWay(id: string): Promise<any> {
    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_CATEGORY_WAY) + '/' + id
    );
    return await lastValueFrom(user);
  }

  // Interest Filters
  interestSkillList(data): Observable<any> {
    return this.http.post(
      this.getAPIURL(config.API_ENDPONT_GET_INTEREST_SKILL_LIST),
      data
    );
  }

  async updateInterestSkillDetail(data): Promise<any> {
    let result = this.http.put(
      this.getAPIURL(config.API_ENDPONT_UPDATE_INTEREST_SKILL_LIST),
      data
    );
    return await lastValueFrom(result);
  }

  async deleteInterestSkillDetail(id: string): Promise<any> {
    let user = this.http.delete(
      this.getAPIURL(config.API_ENDPONT_DELETE_INTEREST_SKILL_LIST) + '/' + id
    );
    return await lastValueFrom(user);
  }

  async userIdWiseInterestSkillList(id: string): Promise<any> {
    const allCategoryType = this.http.get(
      this.getAPIURL(config.API_ENDPONT_GET_INTEREST_SKILL_DETAILS) +
      '/' +
      id
    );
    return await lastValueFrom(allCategoryType);
  }

  async addInterestSkill(data): Promise<any> {
    let result = this.http.post(
      this.getAPIURL(config.API_ENDPONT_ADD_INTEREST_SKILL),
      data
    );
    return await lastValueFrom(result);
  }

  updatePassword(id: string, data: any): Observable<any> {
    return this.http.patch(
      this.getAPIURL(config.API_ENDPONT_CHANGE_PASSWORD) + '/' + id,
      data
    );
  }
}
