import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserDetailService } from 'src/app/shared/services/user-detail.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form: FormGroup;
  changePasswordForm: FormGroup;
  isedit: boolean = false;
  userProfile: any;
  currentProfile: any;
  isShowPassword: boolean = false;
  isShowNewPassword: boolean = false;
  isShowConfirmPassword: boolean = false;
  isChangePassword: boolean = true;

  constructor(private fb: FormBuilder, private apiService: ApiService, private userDetailService: UserDetailService, public commonService: CommonService, private translate: TranslateService) {
    this.userProfile = this.userDetailService.getValue();
    this.currentProfile = this.userProfile.source._value;
    this.commonService.initializeTranslation();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      email: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/)]],
      // password: ['', Validators.required]
    })
    this.form.patchValue(this.currentProfile)

    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
    this.getProfile();

    this.changePasswordForm.valueChanges.subscribe(() => {
      this.isChangePassword = false;
    });
  }

  getProfile() {
    this.apiService.getProfileDetail().subscribe((response) => {
      console.log('response: ', response.data.user.email);
      this.form.patchValue({
        email: response.data.user.email
      })
    })
  }

  updatePassword() {
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      return
    }
    this.isChangePassword = true;
    const json = {
      oldPassword: this.changePasswordForm.controls['oldPassword'].value,
      newPassword: this.changePasswordForm.controls['newPassword'].value,
    }
    this.apiService.updatePassword('', json).subscribe({
      next: (response) => {
        if (!response.is_error) {
          this.apiService.logout();
        } else {
          this.commonService.error(response.message);
        }
      }, error: (err) => {
        this.commonService.error(err.error.message);
      },
    })
  }

  passwordMatchValidator() {
    const password = this.changePasswordForm.controls['newPassword'].value;
    const confirmPassword = this.changePasswordForm.controls['confirmPassword'].value;
    const confirmPasswordControl = this.changePasswordForm.controls['confirmPassword'];
    if (confirmPassword && password !== confirmPassword) {
      confirmPasswordControl.setErrors({ mismatch: true });
    } else if (!confirmPassword) {
      confirmPasswordControl.setErrors({ touched: true });
      confirmPasswordControl.updateValueAndValidity();
    } else if (confirmPassword && password === confirmPassword) {
      confirmPasswordControl.setErrors({ mismatch: false });
      confirmPasswordControl.updateValueAndValidity();
    }
  }

  preventSpace(event: any) {
    if (event.keyCode === 32 || event.key === ' ') {
      return false
    }
  }
}
