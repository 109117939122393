import { Routes } from '@angular/router';
import { ProfileComponent } from 'src/app/components/settings/profile/profile.component';

export const contentRoute: Routes = [
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    data: { breadcrumb: { skip: true } },
  },
  {
    path: 'special-offer',
    loadChildren: () =>
      import('../../components/memebers-offers/memebers-offers.module').then(
        (m) => m.MemebersOffersModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('../../components/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'company-verification',
    loadChildren: () =>
      import(
        '../../components/company-verification/company-verification.module'
      ).then((m) => m.CompanyVerificationModule),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('../../components/welcome/welcome.module').then(
        (m) => m.WelcomeModule
      ),
  },
  {
    path: 'jbf-feed',
    loadChildren: () =>
      import('../../components/jbf-feed/jbf-feed.module').then(
        (m) => m.JbfFeedModule
      ),
  },
  {
    path: 'subscribers',
    loadChildren: () =>
      import('../../components/subscribers/subscribers.module').then(
        (m) => m.SubscribersModule
      ),
  },
  {
    path: 'filters',
    loadChildren: () =>
      import('../../components/filters/filters.module').then(
        (m) => m.FiltersModule
      ),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('../../components/membership/membership.module').then(
        (m) => m.MembershipModule
      ),
  },
  {
    path: 'membership_types',
    loadChildren: () =>
      import('../../components/membership-types/membership-types.module').then(
        (m) => m.MembershipTypesModule
      ),
  },
  {
    path: 'master',
    loadChildren: () =>
      import('../../components/master-roles/master-roles.module').then(
        (m) => m.MasterRolesModule
      ),
  },
  {
    path: 'community_roles',
    loadChildren: () =>
      import('../../components/community-roles/community-roles.module').then(
        (m) => m.CommunityRolesModule
      ),
  },
  {
    path: 'company_roles',
    loadChildren: () =>
      import('../../components/company-roles/company-roles.module').then(
        (m) => m.CompanyRolesModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('../../components/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'total-counts',
    loadChildren: () =>
      import('../../components/total-counts/total-counts.module').then(
        (m) => m.TotalCountsModule
      ),
  },
  {
    path: 'user_account',
    loadChildren: () =>
      import('../../components/users-accounts/users-accounts.module').then(
        (m) => m.UsersAccountsModule
      ),
  },
  {
    path: 'bussiness_account',
    loadChildren: () =>
      import(
        '../../components/bussiness-accounts/bussiness-accounts.module'
      ).then((m) => m.BussinessAccountsModule),
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('../../components/communities/communities.module').then(
        (m) => m.CommunitiesModule
      ),
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('../../components/jobs/jobs.module').then((m) => m.JobsModule),
  },
  {
    path: 'spaces',
    loadChildren: () =>
      import('../../components/spaces/spaces.module').then(
        (m) => m.SpacesModule
      ),
  },
  {
    path: 'events',
    loadChildren: () =>
      import('../../components/events/events.module').then(
        (m) => m.EventsModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../../components/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../../components/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'invoice',
    loadChildren: () =>
      import('../../components/invoice/invoice.module').then(
        (m) => m.InvoiceModule
      ),
  },
  {
    path: 'price_master',
    loadChildren: () =>
      import('../../components/price-master/price-master.module').then(
        (m) => m.PriceMasterModule
      ),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('../../components/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'terms_condition',
    loadChildren: () =>
      import('../../components/terms-condition/terms-condition.module').then(
        (m) => m.TermsConditionModule
      ),
  },
  {
    path: 'privacy_policy',
    loadChildren: () =>
      import('../../components/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];