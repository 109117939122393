import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { Subject, takeUntil } from 'rxjs';
import { Country, State } from 'src/app/interface/country.interface';
import { CommunityData, CompanyData, EventData, JobData, OfferData, ServiceData, SpaceData, UserData } from 'src/app/interface/dashbord.interface';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Global } from 'src/app/shared/services/global';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  users: UserData;
  // company = [];
  setupCompany = [];
  activeCompanies = [];
  spamCompany = [];
  closeCompany = [];
  deleteCompany = [];
  stateData: State[] = [];
  countries: Country[] = [];
  countryId: string;
  states = [];
  cities = [];
  stateId: string;
  selectedCountry: string;
  selectedCity: string;
  // closecompany = [];

  company: CompanyData;
  communities: CommunityData;
  specialOffer: OfferData;
  job: JobData;
  service: ServiceData;
  space: SpaceData;
  event: EventData;
  private destroy$: Subject<unknown> = new Subject();

  constructor(private translate: TranslateService, private dataService: DataService, private router: Router, private toastr: ToastrService, private apiService: ApiService, public commonService: CommonService) {
    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');
    this.commonService.initializeTranslation();
    const browserLang = JSON.parse(this.commonService.getLocalStorage('currentLang'));
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.getUsersData();
    // this.getCompanyData();
    this.loadCountries();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getUsersData() {
    this.commonService.showLoader();
    this.apiService.getUsersDashboard().pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        if (!res.is_error) {
          this.users = res.data.userData;
          this.company = res.data.companyData;
          this.communities = res.data.communityData;
          this.specialOffer = res.data.offerData;
          this.job = res.data.jobData;
          this.service = res.data.serviceData;
          this.space = res.data.spaceData;
          this.event = res.data.eventData;
        } else {
          this.commonService.error(res.message);
        }
        this.commonService.hideLoader();
      },
      error: (error) => {
        this.commonService.hideLoader();
        this.commonService.error("Somting went wrong !");
      }
    })
  }

  /*getCompanyData() {

    let json = { where: { is_deleted: true } }

    this.dataService.post(Global.BASE_API_PATH + "query/execute/conditions/AddCompany", json).subscribe(res => {
      if (res.data) {
        this.company = res.data;

        this.company.forEach(function (item) {

          if (item.is_locked == false && item.is_deleted == false && item.is_verify == 0) {
            this.setupCompany.push(item)
          }
          //active company
          if (item.is_deleted == false && item.is_verify == 1) {
            this.activeCompanies.push(item)
          }
          //spam company 
          if (item.is_locked == true) {
            this.spamCompany.push(item)
          }
          //close company
          if (item.is_deleted == true) {
            // this.closeCompany.push(item)
          }
          //delete company
          if (item.is_deleted == true && item.is_verify == 2) {
            this.deleteCompany.push(item)
          }
        })
      } else {
        this.toastr.error(res.message, "getAdminDashboard");
      }
    })
  } */


  loadCountries() {
    this.apiService.getCountryList().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res.data) {
        this.countries = res.data
      } else {
        this.toastr.error(res.message, "Countries")
      }

    })
  }

  onChangeCountry(id: string) {
    this.countryId = id.split(" ").pop();
    this.loadState();
  }

  loadState() {
    let json = { "country": this.countryId }
    this.stateData = [];
    if (Global.ServerUrl) {
      this.apiService.getCountryStateList(json).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.data) {
          this.states = res.data;
          this.states.filter((element) => {
            if (element.country === this.countryId) {
              this.stateData.push(element)
            }
          })
        }
      })
    }
  }


  onChangeState(id: string) {
    this.stateId = id.split(" ").pop();
    this.loadCity();
  }

  loadCity() {
    let json = { "where": { "state": this.stateId } }

    this.dataService.post(Global.ServerUrl + "api/query/execute/conditions/City", json).pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.data) {
        this.cities = res.data;
      }
    })
  }

}