<div class="main-header-left d-none d-lg-block logo">
  <div class="logo-wrapper logo">
    <a><img class="blur-up lazyloaded" src="assets/images/Logo_2.png" alt="logo" height="50px" width="230px" /></a>
  </div>
</div>
<div class="sidebar custom-scrollbar">
  <!-- <div class="sidebar-user text-center sidebar-bg" style="display: flex">
    <div>
      <img class="img-60 rounded-circle user_image"
        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80"
        alt="#" />
    </div>
    <h6 class="mt-3 f-14 user_name">{{ fullName }}</h6>
  </div>
   <div class="main_finance_headding">
    <p class="finance_headding">Finance</p>
  </div> -->
  <ul class="sidebar-menu">
    <li *ngFor="let firstLevelItem of menuItems" [ngClass]="{ active: firstLevelItem.active}"
      [routerLinkActiveOptions]="{ exact: true }">
      <!-- 1st Level Menu -->
      <!-- Sub menu -->
      <div class="sup-header" *ngIf="firstLevelItem?.type === 'sup'">
        <h2 class="menu-title">{{ firstLevelItem.title | translate }}</h2>
      </div>
      <a [routerLink]="firstLevelItem.path" class="sidebar-header" *ngIf="firstLevelItem?.type === 'sub'"
        (click)="toggleNavActive(firstLevelItem)">
        <app-feather-icon [icon]="firstLevelItem.icon"></app-feather-icon>
        <span> {{ firstLevelItem.title | translate }} </span>
        <i class="fa fa-angle-right pull-right"></i>
      </a>
      <!-- Link menu -->
      <a class="sidebar-header" *ngIf="firstLevelItem.type === 'link'" [routerLink]="firstLevelItem.path"
        [queryParams]="{ status: firstLevelItem?.status }">
        <app-feather-icon [icon]="firstLevelItem.icon"></app-feather-icon>
        <span> {{ firstLevelItem.title | translate }} </span>
      </a>
      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" *ngIf="firstLevelItem.children" [ngClass]="{ 'menu-open': firstLevelItem.active }">
        <li *ngFor="let secondLevelItem of firstLevelItem.children" [ngClass]="{ active: secondLevelItem.active }">
          <!-- Sub -->
          <a [routerLink]="secondLevelItem.path" *ngIf="secondLevelItem.type === 'sub'"
            (click)="toggleNavActive(secondLevelItem)">
            <i class="fa fa-circle"></i><span> {{ secondLevelItem.title | translate}} </span>
            <i class="fa fa-angle-down pull-right"></i>
          </a>
          <!-- Link -->
          <a *ngIf="secondLevelItem.type === 'link'" [routerLink]="secondLevelItem.path"
            [queryParams]="{ status: secondLevelItem.status }">
            <i class="fa fa-circle"></i><span> {{ secondLevelItem.title | translate }} </span>
          </a>
          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="secondLevelItem.children"
            [ngClass]="{ 'menu-open': secondLevelItem.active }">
            <li *ngFor="let thirdLevelItem of secondLevelItem.children" [ngClass]="{ active: thirdLevelItem.active }">
              <!-- Sub -->
              <a [routerLink]="thirdLevelItem.path" *ngIf="thirdLevelItem.type === 'sub'"
                (click)="toggleNavActive(thirdLevelItem)">
                <i class="fa fa-circle"></i><span> {{ thirdLevelItem.title | translate }} </span>
                <i class="fa fa-angle-down pull-right"></i>
              </a>
              <!-- Link -->
              <a *ngIf="thirdLevelItem.type == 'link'" [routerLink]="thirdLevelItem.path">
                <i class="fa fa-circle"></i><span> {{ thirdLevelItem.title | translate }} </span>
              </a>
              <!-- 4th Level Menu -->
              <ul class="sidebar-submenu" *ngIf="thirdLevelItem.children">
                <li *ngFor="let fourthLevelItem of thirdLevelItem.children">
                  <!-- Sub -->
                  <a [routerLink]="fourthLevelItem.path" *ngIf="fourthLevelItem.type === 'sub'"
                    (click)="toggleNavActive(fourthLevelItem)">
                    <i class="fa fa-circle"></i><span> {{ fourthLevelItem.title | translate }} </span>
                    <i class="fa fa-angle-down pull-right"></i>
                  </a>
                  <!-- Link -->
                  <a *ngIf="fourthLevelItem.type == 'link'" [routerLink]="fourthLevelItem.path">
                    <i class="fa fa-circle"></i><span> {{ fourthLevelItem.title | translate }} </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>