<div class="page-main-header" [class.open]="open">
  <div class="main-header-right">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper">
        <a routerLink="/dashboard"><img class="blur-up lazyloaded" src="assets/images/Logo_2.png" alt="" />
        </a>
      </div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a (click)="collaspeSidebar()">
            <app-feather-icon icon="align-left"></app-feather-icon>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{ open: 'openNav' }">
        <li>
          <div class="media align-items-center">
            <button mat-stroked-button [matMenuTriggerFor]="menu" class="custom-button">
              {{ currentLanguage }}
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="setLanguage('en')" [class.selected]="currentLanguage === 'en'">
                EN
              </button>
              <button mat-menu-item (click)="setLanguage('fr')" [class.selected]="currentLanguage === 'fr'">
                FR
              </button>
            </mat-menu>
          </div>
        </li>
        <li class="mt-3">
          <p>Catherine d’Andréa</p>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center">
            <img class="align-self-center pull-right img-50 rounded-circle" src="assets/images/user.png"
              alt="header-user" />
          </div>
          <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <a (click)="logout(openModel)">
                <app-feather-icon icon="log-out"></app-feather-icon>
                {{"LBL_LOGOUT" | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <mat-icon *ngIf="isfullScreen" class="screen border-0" (click)="openFullscreen()">fullscreen</mat-icon>
          <mat-icon *ngIf="!isfullScreen" class="screen" (click)="closeFullscreen()">fullscreen_exit</mat-icon>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        show Icon here
      </div>
    </div>
  </div>
</div>

<ng-template #openModel class="openModel">
  <mat-dialog-content>
    <h3 class="text-center">{{"LBL_LOGOUT" | translate}}?</h3>
    <p class="text-center">{{"LBL_LOGOUT_CONTENT" | translate}}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center">
    <button mat-button (click)="onClick()">{{'LBL_YES' | translate}}</button>
    <button mat-button mat-dialog-close>{{'LBL_NO' | translate}}</button>
  </mat-dialog-actions>
</ng-template>