import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { emailValidator } from '../email-validator.directive';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailService } from 'src/app/shared/services/user-detail.service';
import { Subject, takeUntil } from 'rxjs';

interface IUser {
  email: string;
  password: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  user: IUser;
  selected = 'option2';
  currentLanguage: string = 'en';
  private destroy$: Subject<unknown> = new Subject();
  isLogin: boolean = true;

  constructor(private apiService: ApiService, private router: Router, private cdr: ChangeDetectorRef,
    public toastr: ToastrService, private commonService: CommonService, private translate: TranslateService, private userDetailService: UserDetailService, private fb: FormBuilder) {
    this.user = {} as IUser;
    // this.currentLanguage = JSON.parse(this.commonService.getLocalStorage('currentLang')!);
  }

  ngOnInit(): void {
    const token = localStorage.getItem('userDetails');
    if (token) {
      this.router.navigate(['/dashboard']);
    }
    this.createLoginForm();
    !localStorage.getItem('currentLang') &&
      this.commonService.setCurrentLang('currentLang', this.currentLanguage);
    this.commonService.initializeTranslation();
    this.loginForm.valueChanges.subscribe(() => {
      this.isLogin = false;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setLanguage(lang: string) {
    this.currentLanguage = lang;
    this.commonService.setCurrentLang('currentLang', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.commonService.updateLanguage(lang);
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: [this.user.email, [Validators.required, emailValidator()]],
      password: [this.user.password, Validators.required]
    })
  }

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  OnSubmit() {
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    }
    this.isLogin = true; // Disable button after form submission
    const headers = new HttpHeaders().set('Bypass-Interceptor', 'true');
    this.apiService.getLoginUser(this.loginForm.value, { headers }).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (!response.is_error) {
        this.apiService.token = response.data.token;
        if (response.data.token) {
          this.apiService.isAuthenticated = true;
          localStorage.setItem('userDetails', this.apiService.token);
          this.router.navigate(['/dashboard']);
          this.commonService.setCurrentLang('currentLang', 'en');
          this.commonService.success(this.translate.instant("MSG_LOGIN_SUCCESSFULL"));
        }
      } else {
        this.commonService.error(response.message);
      }
    }, error => {
      this.commonService.error(error.statusText);
    });
    this.userDetailService.user.next(this.loginForm.value);
  }
  //   try {
  //     if (this.loginForm.invalid) {
  //       for (const control of Object.keys(this.loginForm.controls)) {
  //         this.loginForm.controls[control].markAsTouched();
  //       }
  //       return;
  //     }
  //     const headers = new HttpHeaders().set('Bypass-Interceptor', 'true');
  //     const result = await this.apiService.getLoginUser(this.loginForm.value, { headers });
  //     if (!result?.is_error) {
  //       this.commonService.setCurrentLang('currentLang', 'en');
  //       this.commonService.success(result.message);
  //     }
  //   } catch (error) {
  //     // this.commonService.error(error);
  //   }
  //   this.userDetailService.user.next(this.loginForm.value);
  // }
}