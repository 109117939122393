import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format: string = 'dd/MM/yyyy h:mm a', timezone: string = 'UTC', locale: string = 'en'): any {
    return super.transform(value, format, timezone, locale);
  }
}
