import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class HeaderInterceptors implements HttpInterceptor {
  currentLanguage: string = 'en';

  constructor() {
    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    // this.currentLanguage = browserLang;
  }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   this.currentLanguage = localStorage.getItem('currentLang') || 'en';
  //   if (request.headers.has('Bypass-Interceptor')) {
  //     // Bypass interceptor for the request with the custom header
  //     return next.handle(request);
  //   }
  //   // const authToken = this.apiService.getToken();
  //   const authToken = localStorage.getItem('userDetails');
  //   const authRequest = request.clone({ setHeaders: { Authorization: authToken, lang: this.currentLanguage } });

  //   if (authToken && this.currentLanguage) {
  //     return next.handle(authRequest)
  //   }
  //   return next.handle(request);
  //   // .pipe(
  //   //   catchError((error: HttpErrorResponse) => {
  //   //     if (!navigator.onLine) {
  //   //       this.toastr.error('Internet connection is not available.', 'Error');
  //   //     }
  //   //     return throwError(error);
  //   //   })
  //   // );
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let currentLanguage = localStorage.getItem('currentLang') || 'en';
    currentLanguage = currentLanguage.replace(/"/g, '');
    const authToken = localStorage.getItem('userDetails');
    const authRequest = request.clone({ setHeaders: { Authorization: authToken, lang: currentLanguage } });
    if (authToken && currentLanguage) {
      return next.handle(authRequest);
    }
    return next.handle(request);
  }
}
