import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMatSelectPatch]'
})
export class MatSelectPatchDirective implements AfterViewInit {

  constructor(public el: ElementRef) { }

  ngAfterViewInit() {
    if (this.el.nativeElement.parentElement.hasAttribute("aria-owns")) {
      this.el.nativeElement.parentElement.removeAttribute('aria-owns');
    }
  }
}
