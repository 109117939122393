import { Injectable } from '@angular/core';

//Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  active?: boolean;
  status?: string;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  collaspeSidebar: boolean = false;

  constructor() { }

  MENUITEMS: Menu[] = [
    {
      path: '/profile',
      title: 'LBL_SIDEBAR_USER_PROFILE',
      type: 'link',
      icon: 'user',
      active: true,
    },
    {
      title: 'LBL_SIDEBAR_FINANCE',
      type: 'sup',
    },
    {
      path: '/dashboard',
      title: 'LBL_SIDEBAR_DASHBOARD',
      icon: 'home',
      type: 'link',
      active: true,
    },
    {
      path: '/invoice',
      title: 'LBL_SIDEBAR_INVOICES',
      icon: 'archive',
      type: 'link',
      active: false,
    },
    {
      title: 'LBL_SIDEBAR_SALES',
      type: 'sup',
    },
    {
      path: '/special-offer',
      title: 'LBL_SIDEBAR_SPECIAL_OFFER',
      icon: 'gift',
      type: 'link',
      active: false,
    },
    {
      title: 'LBL_SIDEBAR_CONTACT_US',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        //    { path: '/contact-us/pending', title: 'Pending', type: 'link' },      "(20/06/23)  these components Commented because we are currently using only a single
        //    { path: '/contact-us/replied', title: 'replied', type: 'link' }      component instead of utilizing three different components."
        //    { path: '/contact-us/rejected', title: 'rejected', type: 'link' },
        { path: '/contact-us/contact', title: 'LBL_SIDEBAR_CONTACT', type: 'link' },
      ],
    },
    {
      title: 'LBL_SIDEBAR_COMPANY_VERIFICATION',
      icon: 'square',
      type: 'sub',
      active: false,
      children: [
        // { path: '/company-verification/pending', title: 'Pending', type: 'link' },        "(26/06/23)  these components Commented because we are currently using only a single
        // { path: '/company-verification/verified', title: 'Verified', type: 'link' },        component instead of utilizing three different components."
        // { path: '/company-verification/rejected', title: 'Rejected', type: 'link' },
        {
          path: '/company-verification/company-verification',
          title: 'LBL_SIDEBAR_COMPANY_VERIFICATION',
          type: 'link',
        },
      ],
    },
    {
      title: 'LBL_SIDEBAR_CM',
      type: 'sup',
    },
    {
      path: '/welcome',
      title: 'LBL_SIDEBAR_WELCOME_POP_UP',
      icon: 'heart',
      type: 'link',
      active: false,
    },
    {
      path: '/jbf-feed',
      title: 'LBL_SIDEBAR_JBF_FEED',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/subscribers',
      title: 'LBL_SIDEBAR_SUBSCRIBERS',
      icon: 'film',
      type: 'link',
      active: false,
    },
    /*{  comment 10-10-23 Show two times
      title: 'Filters',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/company-verification/pending',
          title: 'Pending',
          type: 'link',
        },
        {
          path: '/company-verification/verified',
          title: 'Verified',
          type: 'link',
        },
        {
          path: '/company-verification/rejected',
          title: 'Rejected',
          type: 'link',
        },
      ],
    },
    {
      path: '/welcome',
      title: 'Welcome Pop-Up',
      icon: 'heart',
      type: 'link',
      active: false,
    },
    {
      path: '/jbf-feed',
      title: 'JBF FEED',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/subscribers',
      title: 'Subscribers',
      icon: 'film',
      type: 'link',
      active: false,
    },*/
    {
      title: 'LBL_SIDEBAR_FILTERS',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        {
          title: 'LBL_SKILL_FILTER',
          type: 'sub',
          active: false,
          children: [
            {
              title: 'LBL_SIDEBAR_HARDSKILLS',
              type: 'sub',
              active: false,
              children: [
                {
                  path: '/filters/categorytype/hardskill',
                  title: 'CATEGORY_TYPE',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/categoryskill/hardskill',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/experience/hardskill',
                  title: 'CATEGORY_EXPERIENCE',
                  type: 'link',
                  active: false,
                },
              ],
            },
            {
              title: 'LBL_SIDEBAR_SOFTWARES',
              type: 'sub',
              active: false,
              children: [
                {
                  path: '/filters/categorytype/softwares',
                  title: 'CATEGORY_TYPE',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/categoryskill/softwares',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/way/softwares',
                  title: 'LBL_CATEGORY_WAY',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/experience/softwares',
                  title: 'CATEGORY_EXPERIENCE',
                  type: 'link',
                  active: false,
                },
              ],
            },
            {
              title: 'LBL_SIDEBAR_LICENSES',
              type: 'sub',
              active: false,
              children: [
                {
                  path: '/filters/categorytype/licenses',
                  title: 'CATEGORY_TYPE',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/categoryskill/licenses',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                  active: false,
                },
                {
                  path: '/filters/experience/licenses',
                  title: 'CATEGORY_EXPERIENCE',
                  type: 'link',
                  active: false,
                },
              ],
            },
            {
              title: 'LBL_LANGUAGE',
              type: 'sub',
              children: [
                {
                  path: '/filters/language',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                },
                {
                  path: '/filters/skills',
                  title: 'CATEGORY_SKILL_2',
                  type: 'link',
                },
                {
                  path: '/filters/experience/languages',
                  title: 'CATEGORY_EXPERIENCE',
                  type: 'link',
                },
              ],
            },
            /*{
              title: 'Theme',
              type: 'sub',
              children: [
                {
                  path: '/filters/theme_name',
                  title: 'Theme Name',
                  type: 'link',
                },
                {
                  path: '/filters/theme_type',
                  title: 'Theme Type',
                  type: 'link',
                },
                {
                  path: '/filters/theme_mode',
                  title: 'Theme Mode',
                  type: 'link',
                },
              ],
            },*/
            {
              title: 'LBL_SIDEBAR_SOFTSKILLS',
              type: 'sub',
              children: [
                {
                  path: '/filters/theme_type/softskills',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                },
                {
                  path: '/filters/theme_mode/softskills',
                  title: 'LBL_CATEGORY_WAY',
                  type: 'link',
                },
                {
                  path: '/filters/experience/softskills',
                  title: 'CATEGORY_EXPERIENCE',
                  type: 'link',
                },
              ],
            },
            {
              title: 'LBL_SIDEBAR_INTERESTS',
              type: 'sub',
              children: [
                {
                  path: '/filters/theme_type/interests',
                  title: 'CATEGORY_TYPE',
                  type: 'link',
                },
                {
                  path: '/filters/category_skill/interests',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                },
                {
                  path: '/filters/theme_mode/interests',
                  title: 'LBL_CATEGORY_WAY',
                  type: 'link',
                },
                {
                  path: '/filters/experience/interests',
                  title: 'LBL_SIDEBAR_SPECIFY',
                  type: 'link',
                },
              ],
            },
            {
              title: 'LBL_SIDEBAR_GOALS',
              type: 'sub',
              children: [
                {
                  path: '/filters/theme_type/goals',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                },
                {
                  path: '/filters/experience/goals',
                  title: 'LBL_SIDEBAR_SPECIFY',
                  type: 'link',
                },
              ],
            },
            {
              title: 'LBL_SIDEBAR_EXPECTATIONS',
              type: 'sub',
              children: [
                {
                  path: '/filters/theme_type/expectations',
                  title: 'CATEGORY_SKILL',
                  type: 'link',
                },
                {
                  path: '/filters/experience/expectations',
                  title: 'LBL_SIDEBAR_SPECIFY',
                  type: 'link',
                },
              ],
            },
          ],
        },
        {
          title: 'RECOMMENDATIONS_FILTERS',
          type: 'sub',
          children: [
            {
              path: '/filters/relationship',
              title: 'LBL_RELATIONSHIP',
              type: 'link',
            },
          ],
        },
        {
          title: 'LBL_SIDEBAR_JOB_FILTERS',
          type: 'sub',
          children: [
            { path: '/filters/industry', title: 'LBL_SIDEBAR_INDUSTRY', type: 'link' },
            { path: '/filters/contract', title: 'LBL_SIDEBAR_CONTRACT', type: 'link' },
            {
              path: '/filters/work_schedule',
              title: 'LBL_WORKSCHEDULE',
              type: 'link',
            },
            {
              path: '/filters/skills_areas',
              title: 'LBL_SIDEBAR_SKILLS_AREAS',
              type: 'link',
            },
            { path: '/filters/flexwork', title: 'Flexwork', type: 'link' },
            {
              path: '/filters/compensation',
              title: 'LBL_COMPENSATION',
              type: 'link',
            },
            {
              path: '/filters/career_Level',
              title: 'CAREER_LEVEL',
              type: 'link',
            },
            {
              path: '/filters/education_qualification',
              title: 'LBL_EDUCATIONAL_QUALIFICATION',
              type: 'link',
            },
          ],
        },
        {
          title: 'SERVICE_FILTERS',
          type: 'sub',
          children: [
            {
              path: '/filters/services-filters',
              title: 'LBL_SIDEBAR_CATEGORY_OF_SERVICE',
              type: 'link',
            },
          ],
        },
        {
          title: 'LBL_SPACES_FILTERS',
          type: 'sub',
          children: [
            {
              path: '/filters/type_of_space',
              title: 'LBL_TYPE_SPACE',
              type: 'link',
            },
            {
              path: '/filters/category_of_space',
              title: 'LBL_SPACECATEGORY',
              type: 'link',
            },
            { path: '/filters/facility', title: 'Facility', type: 'link' },
          ],
        },
        {
          title: 'LBL_EVENTS_FILTERS',
          type: 'sub',
          children: [
            {
              path: '/filters/event_category',
              title: 'LBL_EVENT_CATEGORIES',
              type: 'link',
            },
            {
              path: '/filters/subject_event',
              title: 'LBL_EVENT_SUBJECT',
              type: 'link',
            },
          ],
        },
        {
          title: 'COMMUNITIES_FILTERS',
          type: 'sub',
          children: [
            {
              path: '/filters/community_category',
              title: 'LBL_SIDEBAR_CATEGORY_OF_COMMUNITY',
              type: 'link',
            },
          ],
        },

        // {
        //   title: 'Jobs', type: "sub", children: [
        //     { path: '/jobs/shared_jobs', title: 'Shared Jobs', type: 'link' },
        //     { path: '/jobs/all_applications', title: 'All Applications', type: 'link' },
        //     { path: '/jobs/all_deleted_applications', title: 'All deleted applications', type: 'link' },
        //     { path: '/jobs/spam_jobs', title: 'Spam Jobs', type: 'link' },
        //     { path: '/jobs/expired_jobs', title: 'Expired Jobs', type: 'link' },
        //     { path: '/jobs/deleted_jobs', title: 'Deleted Jobs', type: 'link' },
        //   ]
        // },
        {
          title: 'LBL_USER_FILTERS',
          type: 'sub',
          children: [
            { path: '/filters/status', title: 'LBL_STATUS', type: 'link' },
            {
              path: '/filters/areas_skills',
              title: 'LBL_SIDEBAR_SKILLS_AREAS',
              type: 'link',
            },
            { path: '/filters/degree', title: 'LBL_DEGREE', type: 'link' },
            { path: '/filters/career', title: 'CAREER_LEVEL', type: 'link' },
          ],
        },
        {
          title: 'LBL_COMPANY_FILTER',
          type: 'sub',
          children: [
            {
              path: '/filters/company-status',
              title: 'LBL_STATUS',
              type: 'link',
            },
            {
              path: '/filters/flex-criterias',
              title: 'LBL_FLEX_CRITERIONS',
              type: 'link',
            },
          ],
        },
      ],
    },
    { path: '/faq', title: 'LBL_SIDEBAR_FAQ', icon: 'user', type: 'link', active: false },
    {
      title: 'LBL_SIDEBAR_DATABASE',
      type: 'sup',
    },
    {
      path: '/total-counts',
      title: 'LBL_SIDEBAR_TOTAL_COUNTS',
      icon: 'send',
      type: 'link',
      active: false,
    },
    {
      path: '/user_account/users_list',
      title: 'LBL_SIDEBAR_USERS_ACCOUNTS',
      icon: 'send',
      type: 'link',
      status: 'Not-verified',
    },
    // {
    //   title: "Jobs", icon: "send", type: "sub", active: false, children: [
    //     { path: '/jobs/shared_jobs', title: 'Shared Jobs', type: 'link' },
    //     { path: '/jobs/Set_up_users_list', title: 'All Applications', type: 'link' },
    //     { path: '/jobs/Active_users_list', title: 'All deleted applications', type: 'link' },
    //     { path: '/jobs/Spam_users_list', title: 'Spam Jobs', type: 'link' },
    //     { path: '/jobs/close_users_list', title: 'Expired Jobs', type: 'link' },
    //     { path: '/jobs/Delete_users_list', title: 'Deleted Jobs', type: 'link' },
    //   ]
    // },
    {
      title: 'LBL_SIDEBAR_BUSSINESS_ACCOUNTS',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        // { path: '/bussiness_account/Deleted_business_list', title: 'Deleted bussiness list', type: 'link' },
        // { path: '/bussiness_account/Set_up_business_list', title: 'Set up bussiness list', type: 'link' },        "(29/06/23)  these components Commented because we are currently using only a single
        // { path: '/bussiness_account/Active_business_list', title: 'Active bussiness list', type: 'link' },                 component instead of utilizing different components."
        // { path: '/bussiness_account/Spam_business_list', title: 'Spam bussiness list', type: 'link' },
        // { path: '/bussiness_account/Close_business_list', title: 'Close bussiness list', type: 'link' },
        {
          path: '/bussiness_account/Business_account_list',
          title: 'LBL_SIDEBAR_BUSSINESS_LIST',
          type: 'link',
        },
      ],
    },
    {
      path: '/communities',
      title: 'LBL_SIDEBAR_COMMUNITIES',
      icon: 'send',
      type: 'link',
      active: false,
    },
    // {
    //   title: "Communities", icon: "send", type: "sub", active: false, children: [
    //     { path: '/communities/Shared_jobs', title: 'Shared Communities', type: 'link' },
    //     { path: '/communities/Spam_communities', title: 'Spam_communities', type: 'link' },
    //     { path: '/communities/Deleted_communities', title: 'Deleted_communities', type: 'link' },
    //   ]
    // },
    {
      title: 'LBL_SIDEBAR_JOBS',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        { path: '/jobs/shared_jobs', title: 'LBL_SHARED_JOBS', type: 'link' },
        {
          path: '/jobs/all_applications',
          title: 'LBL_ALL_APPLICATIONS',
          type: 'link',
        },
        {
          path: '/jobs/all_deleted_applications',
          title: 'LBL_ALL_DELETED_APPLICATIONS',
          type: 'link',
        },
        { path: '/jobs/spam_jobs', title: 'LBL_SPAM_JOBS', type: 'link' },
        { path: '/jobs/expired_jobs', title: 'LBL_EXPIRED_JOBS', type: 'link' },
        { path: '/jobs/deleted_jobs', title: 'LBL_DELETED_JOBS', type: 'link' },
        { path: '/jobs/rgpd_jobs', title: 'LBL_SIDEBAR_RGPD_JOBS', type: 'link' }
      ],
    },
    {
      title: 'LBL_SIDEBAR_SPACES',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        { path: '/spaces/spaces', title: 'LBL_SIDEBAR_SPACES', type: 'link' },
        // { path: '/spaces/spam_spaces', title: 'Spam Spaces', type: 'link' },
        // {
        //   path: '/spaces/deleted_spaces',
        //   title: 'Deleted spaces',
        //   type: 'link',
        // },
      ],
    },
    {
      title: 'LBL_SIDEBAR_EVENTS',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [{ path: '/events/events', title: 'LBL_SIDEBAR_EVENTS', type: 'link' }],
    },
    {
      title: 'LBL_SIDEBAR_SERVICE_PRODUCTS',
      icon: 'send',
      type: 'sub',
      active: false,
      children: [
        { path: '/services/services', title: 'LBL_SIDEBAR_SHARED_S/P', type: 'link' },
      ],
    },
    {
      title: 'LBL_SIDEBAR_MASTER',
      type: 'sup',
    },
    {
      path: '/membership',
      title: 'LBL_SIDEBAR_ALL_MEMBERSHIP_MASTER',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/membership_types',
      title: 'LBL_SIDEBAR_MEMBERSHIP_TYPEE',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/master',
      title: 'LBL_SIDEBAR_MASTER_ROLES',
      icon: 'user',
      type: 'link',
      active: false,
    },
    {
      path: '/community_roles',
      title: 'LBL_SIDEBAR_COMMUNITY_ROLES',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/company_roles',
      title: 'LBL_SIDEBAR_COMPANY_ROLES',
      icon: 'square',
      type: 'link',
      active: false,
    },
    {
      path: '/price_master',
      title: 'LBL_SIDEBAR_PRICE_MASTER',
      icon: 'archive',
      type: 'link',
      active: false,
    },
    {
      path: '/terms_condition',
      title: 'LBL_SIDEBAR_TERMS_CONDITION',
      icon: 'alert-circle',
      type: 'link',
      active: false,
    },
    {
      path: '/privacy_policy',
      title: 'LBL_SIDEBAR_PRIVACY_POLICY',
      icon: 'alert-circle',
      type: 'link',
      active: false,
    },
  ];
}
