import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: Menu[];
  fullName: string;
  emailId: string;
  type: any;
  imagePath: string = 'assets/images/user.png';

  constructor(public _navService: NavService, public commonService: CommonService) {
    this.commonService.initializeTranslation();
  }

  ngOnInit(): void {
    this.menuItems = this._navService.MENUITEMS;
    this.fullName = "Catherine d’Andréa";
  }

  //click toggle menu // need to clear
  toggleNavActive(item: any) {
    item.active = !item.active;
  }



}
